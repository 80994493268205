/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Alert from '../../../Alerts/Alert';
import TextArea from '../../../Forms/TextArea';
import ReactLoading from 'react-loading';
import { Content } from '../../../LoadingStyle/StyleLoading';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import {
    del_attachment,
    getFilial,
    getResource,
    post_project,
    put_project,
    get_view_project,
    getTechnicalProject,
    post_technical_project,
    changeStatusTechnical,
    deleteTechnical,
} from '../../../Services/project';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import FileList from '../../../FileList/FileList';
import MyModal from '../../../Modal/MyModal';
import Technical from './Technical';
import TableTechnical from './TableTechnical';
import RenderTechnical from './RenderTechnical';
import AlertNew from '../../../Alerts/AlertNew';
import makeAnimated from 'react-select/animated';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableProdutivity from '../CheckList/TableProdutivity';
import Produtivity from '../CheckList/Produtivity';
import TableProducts from '../CheckList/TableProducts';
import Products from '../CheckList/Products';

export default function Project(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const selectTypeRef = useRef(null);
    const selectStatusRef = useRef(null);
    const selectAreaRef = useRef(null);
    const selectManagerRef = useRef(null);
    const selectCoordinatorRef = useRef(null);
    const selectClientRef = useRef(null);
    const selectEmpresaRef = useRef(null);
    const selectFilialRef = useRef(null);
    const selectMainActivityRef = useRef(null);
    const selectMotiveRef = useRef(null);
    const culturaRef = useRef(null);
    const produtoRef = useRef(null);
    const role = useSelector((state) => state.auth.user.perfis);
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');
    const [reembolsavel, setReembolsavel] = useState('Não');
    const [inadiplente, setInadiplente] = useState('Não');
    const [dedication, setDedication] = useState('');
    const [dedicationProject, setDedicationProject] = useState([]);
    const [id_dedication, setIdDedication] = useState('');
    const [valueDedication, setValueDedication] = useState('');
    const [code, setCode] = useState('');
    const [centroCusto, setCentroCusto] = useState('');
    const [idSalesRecord, setIdSalesRecord] = useState('');
    const [types, setTypes] = useState([]);
    const [id_type, setIdType] = useState('');
    const [status, setStatus] = useState([]);
    const [id_status, setIdStatus] = useState('');
    const [areas, setAreas] = useState([]);
    const [id_area, setIdArea] = useState('');
    const [hectares, setHectares] = useState('');
    const [billingProperty, setBillingProperty] = useState('');
    const [idMainActivity, setIdMainActivity] = useState('');
    const [valueCulture, setValueCulture] = useState('');
    const [idCulture, setIdCulture] = useState('');
    const [optionsCulture, setOptionsCulture] = useState([]);
    const [alertCultura, setAlertCultura] = useState('');
    const [produtivity, setProdutivity] = useState([]);
    const [product, setProduct] = useState([]);
    const [idProduct, setIdProduct] = useState('');
    const [optionsProduct, setOptionsProduct] = useState([]);
    const [alertProduto, setAlertProduto] = useState('');
    const [valueProduct, setValueProduct] = useState('');
    const [idMotive, setIdMotive] = useState('');
    const [optionsMotive, setOptionsMotive] = useState([]);
    const [managers, setManagers] = useState([]);
    const [id_manager, setIdManager] = useState('');
    const [coordinators, setCoordinators] = useState([]);
    const [id_coordinator, setIdCoordinator] = useState('');
    const [customers, setCustomers] = useState([]);
    const [id_client, setIdClient] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [value, setValue] = useState('');
    const [description, setDescription] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [imposto, setImposto] = useState('');
    const [margemAdm, setMargemAdm] = useState('');
    const [margemLqd, setMargemLqd] = useState('');
    const [margemAd, setMargemAd] = useState('');
    const [loading, setLoading] = useState(false);
    const [technical, setTechnical] = useState([]);
    const [id_technician, setIdTechnician] = useState([]);
    const [remuneration, setRemuneration] = useState([]);
    const [id_remuneration, setIdRemuneration] = useState([]);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [modal, setModal] = useState(false);
    const [value_technical, setValueTechnical] = useState('');
    const [technicalProject, setTechnicalProject] = useState([]);
    const [errorSubmitTechnical, setErrorSubmitTechnical] = useState('');
    const [successSubmitTechnical, setSuccessSubmitTechnical] = useState('');
    const [errorsTechnical, setErrorsTechnical] = useState([]);
    const [dataAlocacao, setDataAlocacao] = useState('');
    const animatedComponents = makeAnimated();
    const [idEmpresa, setIdEmpresa] = useState('');
    const [optionsEmpresa, setOptionsEmpresa] = useState([]);
    const [idFilial, setIdFilial] = useState('');
    const [optionsFilial, setOptionsFilial] = useState([]);
    const [descriptionAdditionalMargin, setDescriptionAdditionalMargin] =
        useState('');
    useEffect(() => {
        getResources();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        getTechnicalsProject();
    }, []);

    useEffect(() => {
        permissionRole();
        return () => {
            permissionRole();
        };
    }, [props.match.params.action]);

    useEffect(() => {
        renderTechnical();
    }, [technicalProject]);

    useEffect(() => {
        isEdit();
    }, [props.match.params.action, id_type.value]);

    useEffect(() => {
        filial(idEmpresa.value);
    }, [idEmpresa]);

    //Função que verifica se o usuário tem permissão para acessar a tela de edição
    function isEdit() {
        if (props.match.params.action === 'edit') {
            if (
                role.indexOf(1) < 0 &&
                role.indexOf(8) < 0 &&
                role.indexOf(3) < 0 &&
                role.indexOf(6) < 0 &&
                role.indexOf(9) < 0
            ) {
                props.history.push(`/project/${props.match.params.id}/view`);
            }
            if (id_type.value === 1) {
                props.history.push(`/project/${props.match.params.id}/view`);
            }
        }
    }

    //Função que renderiza os técnicos adicionados ao projeto
    function renderTechnical() {
        return (
            <RenderTechnical
                coordinator={id_coordinator}
                manager={id_manager}
                handleCheeseChange={changeStatusActive}
                roles={role}
                formatReal={formatReal}
                technical={technicalProject}
                isDisabled={props.match.params.action === 'view' ? true : false}
                handleRemove={deleteTechnicalProject}
            />
        );
    }

    function permissionRole() {
        if (
            props.match.params.action === 'edit' ||
            !props.match.params.action
        ) {
            if (
                role.indexOf(1) < 0 &&
                role.indexOf(8) < 0 &&
                role.indexOf(3) < 0 &&
                role.indexOf(6) < 0 &&
                role.indexOf(9) < 0
            ) {
                return props.history.push('/projects');
            }
        }
        if (props.match.params.action === 'view') {
            if (
                role.length === 1 &&
                role.indexOf(10) >= 0 &&
                role.indexOf(8) < 0 &&
                role.indexOf(9) < 0
            ) {
                return history.push('/index');
            }
        }
    }

    //Função que traz as filiais relacionados a empresa
    function filial(idEmpresa) {
        getFilial(idEmpresa).then((resp) => {
            setOptionsFilial(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
        });
    }

    //Função que traz da api os técnicos do projeto
    function getTechnicalsProject() {
        props.match.params.action &&
            getTechnicalProject(props.match.params.id).then((resp) => {
                setTechnicalProject(resp.data);
            });
    }

    //Função que traz dados necessários para preencher os selects de cadastro
    function getResources() {
        getResource().then((resp) => {
            setTechnical(
                resp.data.technical.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setDedicationProject(
                resp.data.dedication.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setRemuneration(
                resp.data.remuneration.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );

            setTypes(
                resp.data.types.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setStatus(
                resp.data.status.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setAreas(
                resp.data.areasAtivo.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setManagers(
                resp.data.managers.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setCoordinators(
                resp.data.coordinators.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setCustomers(
                resp.data.customers.map((desc) => ({
                    value: desc.id,
                    label: desc.nome,
                }))
            );
            setOptionsEmpresa(
                resp.data.empresa.map((desc) => ({
                    value: desc.id,
                    label: desc.nome,
                }))
            );
            setOptionsCulture(
                resp.data.cultureAtivo.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsProduct(
                resp.data.product.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsMotive(
                resp.data.motive.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
        });
    }

    const handleProdutivity = (e) => {
        e.preventDefault();
        var errorCheck = false;

        if (
            !idCulture ||
            idCulture.length <= 0 ||
            (!valueCulture && valueCulture !== 0)
        ) {
            return setAlertCultura(
                'É necessário escolher uma cultura e preencher o valor para adicionar uma nova produtividade !'
            );
            errorCheck = true;
        }

        let validate =
            produtivity.filter(
                (desc) => parseInt(desc.idCulture) === parseInt(idCulture.value)
            ) || [];
        if (validate.length > 0) {
            return setAlertCultura(
                'Não é possivel adicionar uma mesma cultura'
            );
            errorCheck = true;
        }
        setAlertCultura('');
        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idCulture: idCulture.value,
            nameCulture: idCulture.label,
            value: valueCulture,
        };

        setProdutivity(produtivity.concat(data));
        setIdCulture([]);
        setValueCulture('');
    };

    const handleProduct = (e) => {
        e.preventDefault();
        var errorCheck = false;
        console.log(!valueProduct);

        if (
            !idProduct ||
            idProduct.length <= 0 ||
            (!valueProduct && valueProduct !== 0)
        ) {
            return setAlertProduto(
                'É necessário escolher um produto e preencher o valor para adicionar um novo produto !'
            );
            errorCheck = true;
        }

        let validate =
            product.filter(
                (desc) => parseInt(desc.idProduct) === parseInt(idProduct.value)
            ) || [];
        if (validate.length > 0) {
            return setAlertProduto('Não é possivel adicionar um mesmo produto');
            errorCheck = true;
        }

        setAlertProduto('');

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idProduct: idProduct.value,
            nameProduct: idProduct.label,
            value: valueProduct,
        };

        setProduct(product.concat(data));
        setIdProduct([]);
        setValueProduct('');
    };

    const handleDeleteProduct = (id) => {
        let valueProduct = product.filter(
            (desc) => parseInt(desc.id) === parseInt(id)
        )[0].value;
        setProduct(product.filter((desc) => desc.id !== id));
    };

    const handleDeleteProdutivity = (id) => {
        setProdutivity(produtivity.filter((desc) => desc.id !== id));
    };

    const changeEmpresa = (e) => {
        setIdEmpresa(e);
        setIdFilial('');
    };

    //Função que salva o técnico no projeto
    const postTechnical = (e) => {
        e.preventDefault();
        // setLoading(true)
        const data = {
            id_technical: parseInt(id_technician.value),
            id_remuneration: parseInt(id_remuneration.value),
            value_technical: value_technical,
            id_project: parseInt(props.match.params.id),
            dedication: valueDedication,
            id_dedication: id_dedication.value,
            dataAlocacao: dataAlocacao,
        };
        post_technical_project(data)
            .then((resp) => {
                getTechnicalsProject();
                setLoading(false);
                setErrorsTechnical('');
                setError([]);
                setSuccessSubmitTechnical(resp.data.success);
                setIdTechnician('');
                setIdRemuneration('');
                setDedication('');
                setIdDedication('');
                setValueDedication('');
                setValueTechnical('');
                setDataAlocacao('');
                setTimeout(() => {
                    setSuccessSubmitTechnical('');
                }, 3000);
            })
            .catch((e) => {
                setLoading(false);
                setSuccessSubmitTechnical('');
                setErrorsTechnical(e.response.data.error);
                setErrorSubmitTechnical(e.response.data.errorSubmitTechnical);
                setTimeout(() => {
                    setErrorSubmitTechnical('');
                }, 6000);
            });
        getTechnicalsProject();
    };

    //Função que define os arquivos de contrato no estado da aplicação
    const handleUpload = (files) => {
        const uploadedFile = files.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),

            progress: 0,
            uploaded: false,
            error: false,
            url: null,
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    };

    //Função que deleta o arquivo de contrato
    const handleDeleteAttachment = (id) => {
        const upload = uploadedFiles.filter((file) => file.id === id);
        if (upload[0].url) {
            del_attachment(id).then((resp) => viewPerId(props.match.params.id));
        } else {
            setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
        }
    };

    //Função que pega os clientes escolhidos no projeto
    const handleChangeClient = (newValue: any, actionMeta: any) => {
        setIdClient(newValue);
    };

    //Função que traz os registros de determinado ID de projeto
    function viewPerId() {
        props.match.params.action &&
            get_view_project(props.match.params.id)
                .then((resp) => {
                    setIdType({
                        value: resp.data.project[0].id_tipo,
                        label: resp.data.project[0].descTipo,
                    });
                    setIdArea({
                        value: resp.data.project[0].id_area,
                        label: resp.data.project[0].descArea,
                    });
                    setIdStatus({
                        value: resp.data.project[0].id_status,
                        label: resp.data.project[0].descStatus,
                    });
                    setIdManager({
                        value: resp.data.project[0].id_gerente,
                        label: resp.data.project[0].gerente,
                    });
                    setIdCoordinator({
                        value: resp.data.project[0].id_coordenador,
                        label: resp.data.project[0].coordenador,
                    });
                    setIdClient(
                        resp.data.client.map((desc) => ({
                            value: desc.id_cliente,
                            label: desc.cliente,
                        }))
                    );
                    setIdSalesRecord(resp.data.project[0].id_registro_venda);
                    setImposto(resp.data.project[0].imposto);
                    setMargemAdm(resp.data.project[0].margemAdm);
                    setMargemLqd(resp.data.project[0].margemLqd);
                    setMargemAd(resp.data.project[0].margemAd);
                    setDescriptionAdditionalMargin(
                        resp.data.project[0].marginAdDescription
                            ? resp.data.project[0].marginAdDescription
                            : ''
                    );
                    setDescription(resp.data.project[0].descricao);
                    setValue(resp.data.project[0].valor_projeto);
                    setStartDate(resp.data.project[0].data_inicio);
                    setEndDate(resp.data.project[0].data_fim);
                    setTitle(resp.data.project[0].titulo);
                    setCode(resp.data.project[0].codigo_w3);
                    setCentroCusto(resp.data.project[0].centro_custo);
                    setInadiplente(
                        parseInt(resp.data.project[0].inadiplente) === 1
                            ? 'Sim'
                            : 'Não'
                    );
                    setReembolsavel(
                        parseInt(resp.data.project[0].reembolsavel) === 1
                            ? 'Sim'
                            : 'Não'
                    );
                    setIdEmpresa({
                        value: resp.data.project[0].id_empresa,
                        label: resp.data.project[0].empresa,
                    });
                    setIdFilial({
                        value: resp.data.project[0].id_filial,
                        label: resp.data.project[0].filial,
                    });
                    setBillingProperty(resp.data.project[0].billing_property);
                    setIdMainActivity({
                        value: resp.data.project[0].id_main_activity,
                        label: resp.data.project[0].main_activity,
                    });
                    setHectares(resp.data.project[0].hectares_propriedade);
                    setUploadedFiles(
                        resp.data.attachment.map((file) => ({
                            id: file.id,
                            name: file.nome,
                            readableSize: filesize(file.size),
                            uploaded: true,
                            url: file.link,
                        }))
                    );
                    setProdutivity(
                        resp.data.produtivity.map((desc) => ({
                            id: uniqueId(),
                            idCulture: desc.id_cultura,
                            nameCulture: desc.cultura,
                            value: desc.valor,
                        }))
                    );
                    setProduct(
                        resp.data.product.map((desc) => ({
                            id: uniqueId(),
                            idProduct: desc.id_produto,
                            nameProduct: desc.produto,
                            value: desc.valor,
                        }))
                    );
                    setIdMotive({
                        value: resp.data.project[0].id_motive,
                        label: resp.data.project[0].descMotive,
                    });
                })
                .catch((e) => {
                    setAlert(e.response.data.error);
                    setTimeout(() => {
                        setAlert('');
                        return props.history.push('/projects');
                    }, 5000);
                });
    }

    //Função que salva registro no banco de dados
    const handleForm = (e) => {
        e.preventDefault();
        var errorCheck = false;
        setLoading(true);

        if (props.match.params.action === 'edit' && id_type.value === 1) {
            if (product.length <= 0) {
                setAlertProduto(
                    'Você precisa adicionar produtos no registro de venda !'
                );
                errorCheck = true;
                produtoRef.current.scrollIntoView();
            } else {
                setAlertProduto('');
            }
        }

        if (props.match.params.action === 'edit' && id_type.value === 1) {
            if (produtivity.length <= 0) {
                setAlertCultura(
                    'Você precisa adicionar cultura no registro de venda !'
                );
                errorCheck = true;
                culturaRef.current.scrollIntoView();
            } else {
                setAlertCultura('');
            }
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        let newClient = [];
        id_client.map((desc) => newClient.push(desc.value));

        const data = new FormData();
        var uploadedFile = uploadedFiles.filter((desc) => !desc.url);

        var resultado = '';
        let attachment = false;
        for (let i = 0; i < uploadedFile.length; i++) {
            if (uploadedFile[i].file) {
                data.append(`file${i}`, uploadedFile[i].file);
                resultado = i;
                attachment = true;
            }
        }
        data.append('title', title);
        data.append('type', parseInt(id_type.value));
        data.append('code', code);
        data.append('cod_centro_custo', centroCusto ? centroCusto : '');
        data.append('reembolsavel', reembolsavel === 'Sim' ? 1 : 0);
        data.append('status', parseInt(id_status.value));
        data.append('area', parseInt(id_area.value));
        data.append('hectaresProperty', hectares);
        data.append('billingProperty', billingProperty);
        data.append('idMainActivity', parseInt(idMainActivity.value));
        data.append('produtivity', JSON.stringify(produtivity));
        data.append('product', JSON.stringify(product));
        data.append('manager', parseInt(id_manager.value));
        data.append('coordinator', parseInt(id_coordinator.value));
        data.append('client', newClient);
        data.append('start_date', startDate);
        data.append('end_date', endDate);
        data.append('value_project', value > 0 ? value : '');
        data.append('description', description);
        data.append('qtd', resultado);
        data.append('isAttachment', attachment);
        data.append('inadiplente', inadiplente === 'Sim' ? 1 : 0);
        data.append('id_empresa', parseInt(idEmpresa.value));
        data.append('id_filial', parseInt(idFilial.value));
        data.append('margemAd', margemAd);
        data.append('margemAdDescription', descriptionAdditionalMargin);

        if (props.match.params.action === 'edit') {
            data.append('_method', 'put');
        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            put_project(id, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        // setError('')
                        setAlert('');
                    }, 3000);
                });
        } else {
            post_project(data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setError([]);
                    setSuccess(resp.data.success);
                    setId(resp.data.id_project);
                    setModal(true);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setError(e.response.data.error);
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        // setError('')
                        setAlert('');
                    }, 3000);
                });
        }
    };

    //Função que fecha modal ao escolher que não deseja relacionar técnico ao projeto
    const handleClose = (event) => {
        setModal(false);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    };

    //Função que redireciona usuário para relacionar técnico ao projeto
    const handleEdit = (event) => {
        setModal(false);
        props.history.push(`project/${id}/edit`);
        setTimeout(() => {
            setSuccess('');
        }, 1500);
    };

    //Função que altera o status do técnico no projeto
    const changeStatusActive = (event) => {
        const id = event.target.id;
        let checked = event.target.checked === true ? 1 : 0;
        const data = {
            ativo: checked,
        };
        changeStatusTechnical(id, data)
            .then((resp) => {
                getTechnicalsProject();
                setErrorSubmitTechnical('');
                setSuccessSubmitTechnical(resp.data.success);
                setTimeout(() => {
                    setSuccessSubmitTechnical('');
                }, 3000);
            })
            .catch((e) => {
                setSuccessSubmitTechnical('');
                setErrorSubmitTechnical(e.response.data.errorSubmitTechnical);
                setTimeout(() => {
                    setErrorSubmitTechnical('');
                }, 3000);
            });
    };

    //Função que deleta o técnico do projeto
    const deleteTechnicalProject = (id) => {
        deleteTechnical(id)
            .then((resp) => {
                getTechnicalsProject();
                setErrorSubmitTechnical('');
                setSuccessSubmitTechnical(resp.data.success);
                setTimeout(() => {
                    setSuccessSubmitTechnical('');
                }, 3000);
            })
            .catch((e) => {
                setSuccessSubmitTechnical('');
                setErrorSubmitTechnical(e.response.data.errorSubmitTechnical);
                setTimeout(() => {
                    setErrorSubmitTechnical('');
                }, 3000);
            });
    };

    //Função que formata o campo valor
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    return (
        <>
            {/* <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage title={props.match.params.action === 'edit' ? "Edição de Projetos" : props.match.params.action === 'view' ? "Visualização de Projetos" : "Cadastro de Projetos"}> */}
            {loading === true ? (
                <Content>
                    <ReactLoading
                        type="spinningBubbles"
                        color="blue"
                        height={200}
                        width={100}
                    />
                </Content>
            ) : (
                ''
            )}
            {alert ? <AlertNew type="danger">{alert}</AlertNew> : ''}
            {success ? <AlertNew type="success">{success}</AlertNew> : ''}
            <MyModal
                show={modal}
                onHide={handleClose}
                title="Sucesso"
                content="O projeto foi cadastrado corretamente ! Deseja adicionar técnicos ao projeto ?"
                cancel={handleClose}
                del={handleEdit}
                to={window.location}
                type="primary"
                textButton="Sim"
            />
            <div
                style={{
                    width: '100%',
                    display: loading === true ? 'none' : 'block',
                    opacity: success || alert ? 0.1 : 1,
                }}
            >
                <Form onSubmit={handleForm}>
                    {props.match.params.action && (
                        <div className="row">
                            <div className="col-md-4">
                                <p style={{ height: '40px' }}>
                                    <span
                                        style={{
                                            height: '50px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        <a
                                            href="#/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.history.push(
                                                    `/check_list/${idSalesRecord}/view`
                                                );
                                                e.preventDefault();
                                            }}
                                        >
                                            Registro de Venda
                                        </a>
                                    </span>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p style={{ height: '40px' }}>
                                    <span
                                        style={{
                                            height: '50px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        <a
                                            href="#/"
                                            onClick={(e) => {
                                                dispatch({
                                                    type: 'changeProjectReportExtraCharge',
                                                    payload: {
                                                        value: props.match
                                                            .params.id,
                                                        label: title,
                                                    },
                                                });
                                                e.preventDefault();
                                                props.history.push(
                                                    `/extra_charges`
                                                );
                                                e.preventDefault();
                                            }}
                                        >
                                            Serviços Extras
                                        </a>
                                    </span>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p style={{ height: '40px' }}>
                                    <span
                                        style={{
                                            height: '50px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        <a
                                            href="#/"
                                            onClick={(e) => {
                                                dispatch({
                                                    type: 'changeProjectReportProjectAmendment',
                                                    payload: {
                                                        value: props.match
                                                            .params.id,
                                                        label: title,
                                                    },
                                                });
                                                e.preventDefault();
                                                props.history.push(
                                                    `/contract_changes`
                                                );
                                                e.preventDefault();
                                            }}
                                        >
                                            Alterações de Projetos
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-6">
                            <NumberFormat
                                label="Código W3"
                                name="code"
                                icon="fas fa-key"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                thousandSeparator={''}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                allowNegative={false}
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 999999999
                                    );
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <NumberFormat
                                label="Código Centro de Custo"
                                name="code"
                                icon="fas fa-key"
                                fixedDecimalScale={true}
                                decimalScale={0}
                                thousandSeparator={''}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                allowNegative={false}
                                onChange={(e) => setCentroCusto(e.target.value)}
                                value={centroCusto}
                                disabled={
                                    props.match.params.action === 'edit'
                                        ? true
                                        : props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                icon="fas fa-calendar-alt"
                                label="Título"
                                type="text"
                                value={title}
                                maxLength="255"
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Digite o título"
                                required={true}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>{error.title}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-6">
                            <label>Tipo</label>
                            <Select
                                value={id_type}
                                ref={selectTypeRef}
                                label="Single select"
                                options={types}
                                onChange={(e) => setIdType(e)}
                                isDisabled={
                                    props.match.params.action === 'view' ||
                                    props.match.params.action === 'edit'
                                        ? true
                                        : false
                                }
                            />
                            {id_type.length <= 0 && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_type}
                                    onFocus={() =>
                                        selectTypeRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                            {error ? (
                                <p style={{ color: 'red' }}>{error.type}</p>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label>Empresa *</label>
                            <Select
                                required={true}
                                value={idEmpresa}
                                ref={selectEmpresaRef}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                options={optionsEmpresa}
                                onChange={changeEmpresa}
                            />
                            {!idEmpresa && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={idEmpresa}
                                    onFocus={() =>
                                        selectEmpresaRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                        </div>
                        <div className="col-md-6">
                            <label>Filial *</label>
                            <Select
                                required={true}
                                value={idFilial}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                ref={selectFilialRef}
                                options={optionsFilial}
                                onChange={(e) => setIdFilial(e)}
                            />
                            {!idFilial && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={idFilial}
                                    onFocus={() =>
                                        selectFilialRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <label>Status</label>
                            <Select
                                value={id_status}
                                ref={selectStatusRef}
                                label="Single select"
                                options={status}
                                onChange={(e) => setIdStatus(e)}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {id_status.length <= 0 && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_status}
                                    onFocus={() =>
                                        selectStatusRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                            {error ? (
                                <p style={{ color: 'red' }}>{error.status}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-6">
                            <label>Área</label>
                            <Select
                                value={id_area}
                                ref={selectAreaRef}
                                label="Single select"
                                options={areas}
                                onChange={(e) => setIdArea(e)}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {id_area.length <= 0 && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_area}
                                    onFocus={() =>
                                        selectAreaRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                            {error ? (
                                <p style={{ color: 'red' }}>{error.area}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <NumberFormat
                                icon="fas fa-tractor"
                                label="Tamanho da Propriedade (Hectáres) *"
                                name="acre"
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                required={true}
                                customInput={Input}
                                onChange={(e) =>
                                    setHectares(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={hectares}
                                disabled={
                                    props.match.params.action === 'edit'
                                        ? true
                                        : props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 999999999
                                    );
                                }}
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>
                                    {error.hectaresProperty}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <NumberFormat
                                icon="fas fa-money-check-alt"
                                label="Faturamento Anual da Propriedade *"
                                name="billing property"
                                allowNegative={false}
                                required={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setBillingProperty(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={`${billingProperty}`.replace('.', ',')}
                                disabled={
                                    props.match.params.action === 'edit'
                                        ? true
                                        : props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 999999999
                                    );
                                }}
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>
                                    {error.billingProperty}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <label>Atividade Principal do Cliente *</label>
                            <Select
                                required={true}
                                value={idMainActivity}
                                ref={selectMainActivityRef}
                                label="Atividade Principal do Cliente"
                                options={areas}
                                onChange={(e) => setIdMainActivity(e)}
                                isDisabled={
                                    props.match.params.action === 'edit'
                                        ? true
                                        : props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {!idMainActivity && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={idMainActivity}
                                    onFocus={() =>
                                        selectMainActivityRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                        </div>
                        {id_status.value === 5 ? (
                            <div className="col-md-4">
                                <label>Motivo de Encerramento</label>
                                <Select
                                    required={false}
                                    value={idMotive}
                                    ref={selectMotiveRef}
                                    label="Motivo de Encerramento"
                                    options={optionsMotive}
                                    onChange={(e) => setIdMainActivity(e)}
                                    isDisabled={true}
                                />
                                {!idMotive && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idMotive}
                                        onFocus={() =>
                                            selectMotiveRef.current.focus()
                                        }
                                        required={false}
                                    />
                                )}
                            </div>
                        ) : null}
                        <hr></hr>
                        <br></br>
                        <div
                            className="card-header col-md-12"
                            style={{ marginTop: '3rem' }}
                        >
                            <h3 ref={culturaRef} className="card-title">
                                Produtividade *
                            </h3>
                        </div>
                        <br></br>
                        <div className="col-md-12">
                            {alertCultura ? (
                                <Alert type="danger">{alertCultura}</Alert>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-5" style={{ marginTop: '1rem' }}>
                            <label>Cultura *</label>
                            <Select
                                value={idCulture}
                                label="typeCharge"
                                options={optionsCulture}
                                onChange={(e) => setIdCulture(e)}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                        </div>
                        <div className="col-md-5" style={{ marginTop: '1rem' }}>
                            <NumberFormat
                                icon="fa-solid fa-asterisk"
                                label="Quantidade *"
                                name="valueCulture"
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setValueCulture(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={valueCulture}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 999999999
                                    );
                                }}
                            />
                        </div>
                        <div className="col-md-2" style={{ marginTop: '1rem' }}>
                            <div
                                className="input-group mb-3"
                                style={{ marginTop: '30px' }}
                            >
                                <button
                                    onClick={handleProdutivity}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    <span className="fas fa-plus"></span>
                                </button>
                            </div>
                        </div>
                        <div
                            className="col-md-12"
                            style={{ marginBot: '30px' }}
                        >
                            <TableProdutivity>
                                <Produtivity
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    produtivity={produtivity}
                                    formatReal={formatReal}
                                    handleDeleteProdutivity={
                                        handleDeleteProdutivity
                                    }
                                />
                            </TableProdutivity>
                        </div>

                        <br></br>

                        <div
                            className="card-header col-md-12"
                            style={{ marginTop: '3rem' }}
                        >
                            <h3 ref={produtoRef} className="card-title">
                                Produtos *
                            </h3>
                        </div>
                        <div className="col-md-12">
                            {alertProduto ? (
                                <Alert type="danger">{alertProduto}</Alert>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-5" style={{ marginTop: '1rem' }}>
                            <label>Produto *</label>
                            <Select
                                value={idProduct}
                                label="typeCharge"
                                options={optionsProduct}
                                onChange={(e) => setIdProduct(e)}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                        </div>
                        <div className="col-md-5" style={{ marginTop: '1rem' }}>
                            <NumberFormat
                                icon="fas fa-money-check-alt"
                                label="Valor *"
                                name="valueProduct"
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setValueProduct(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={valueProduct}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 999999999
                                    );
                                }}
                            />
                        </div>
                        <div className="col-md-2" style={{ marginTop: '1rem' }}>
                            <div
                                className="input-group mb-3"
                                style={{ marginTop: '30px' }}
                            >
                                <button
                                    onClick={handleProduct}
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    <span className="fas fa-plus"></span>
                                </button>
                            </div>
                        </div>
                        <div
                            className="col-md-12"
                            style={{ marginBot: '30px' }}
                        >
                            <TableProducts>
                                <Products
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    product={product}
                                    formatReal={formatReal}
                                    handleDeleteProduct={handleDeleteProduct}
                                />
                            </TableProducts>
                        </div>
                        <br></br>
                        {/* <div className="col-md-3 mt-1">
                                <RadioMaterial
                                    row 
                                    label="Reembolsável ?"
                                    row 
                                    aria-label="reembolsavel" 
                                    name="reembolsavel" 
                                    value={reembolsavel} 
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '10px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={(e)=>setReembolsavel(e.target.value)}
                                >
                                    <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                    <FormControlLabel value="Não" control={<Radio />} label="Não" />
                                </RadioMaterial>
                            </div>
                            <div className="col-md-3 mt-1">
                                <RadioMaterial
                                    row 
                                    label="Inadiplente ?"
                                    row 
                                    aria-label="inadiplente" 
                                    name="inadiplente" 
                                    value={inadiplente} 
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '10px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={(e)=>setInadiplente(e.target.value)}
                                >
                                    <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                    <FormControlLabel value="Não" control={<Radio />} label="Não" />
                                </RadioMaterial>
                            </div> */}
                    </div>
                    <div className="row" style={{ paddingTop: '6rem' }}>
                        <div className="col-md-6 mt-1">
                            <RadioMaterial
                                row
                                label="Reembolsável ?"
                                aria-label="reembolsavel"
                                name="reembolsavel"
                                value={reembolsavel}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                styleLabel={{
                                    color: '#BEBEBE',
                                    fontSize: '10px',
                                    marginBottom: '15px',
                                }}
                                onChange={(e) =>
                                    setReembolsavel(e.target.value)
                                }
                            >
                                <FormControlLabel
                                    value="Sim"
                                    control={<Radio />}
                                    label="Sim"
                                />
                                <FormControlLabel
                                    value="Não"
                                    control={<Radio />}
                                    label="Não"
                                />
                            </RadioMaterial>
                        </div>
                        <div className="col-md-6 mt-1">
                            <RadioMaterial
                                row
                                label="Inadiplente ?"
                                aria-label="inadiplente"
                                name="inadiplente"
                                value={inadiplente}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                styleLabel={{
                                    color: '#BEBEBE',
                                    fontSize: '10px',
                                    marginBottom: '15px',
                                }}
                                onChange={(e) => setInadiplente(e.target.value)}
                            >
                                <FormControlLabel
                                    value="Sim"
                                    control={<Radio />}
                                    label="Sim"
                                />
                                <FormControlLabel
                                    value="Não"
                                    control={<Radio />}
                                    label="Não"
                                />
                            </RadioMaterial>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Gerente</label>

                            <Select
                                value={id_manager}
                                ref={selectManagerRef}
                                label="Single select"
                                options={managers}
                                onChange={(e) => setIdManager(e)}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {id_manager.length <= 0 && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_manager}
                                    onFocus={() =>
                                        selectManagerRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                            {error ? (
                                <p style={{ color: 'red' }}>{error.manager}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <label>Coordenador</label>
                            <Select
                                ref={selectCoordinatorRef}
                                value={id_coordinator}
                                label="Single select"
                                options={coordinators}
                                onChange={(e) => setIdCoordinator(e)}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {id_coordinator.length <= 0 && (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_coordinator}
                                    onFocus={() =>
                                        selectCoordinatorRef.current.focus()
                                    }
                                    required={true}
                                />
                            )}
                            {error ? (
                                <p style={{ color: 'red' }}>
                                    {error.coordinator}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <label>Cliente</label>
                            <Select
                                value={id_client}
                                isMulti
                                name="clientes"
                                options={customers}
                                components={animatedComponents}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                ref={selectClientRef}
                                classNamePrefix="select"
                                onChange={handleChangeClient}
                                isDisabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />

                            {id_client ? (
                                id_client.length <= 0 ? (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={id_client}
                                        onFocus={() =>
                                            selectClientRef.current.focus()
                                        }
                                        required={true}
                                    />
                                ) : (
                                    ''
                                )
                            ) : id_client == null ? (
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: '100%',
                                        height: 0,
                                        position: 'absolute',
                                    }}
                                    defaultValue={id_client}
                                    onFocus={() =>
                                        selectClientRef.current.focus()
                                    }
                                    required={true}
                                />
                            ) : (
                                ''
                            )}
                            {error ? (
                                <p style={{ color: 'red' }}>{error.client}</p>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Input
                                icon="fas fa-calendar-alt"
                                label="Data Início"
                                onChange={(e) => setStartDate(e.target.value)}
                                type="date"
                                value={startDate}
                                placeholder=""
                                required={true}
                                max={endDate}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>{error.end_date}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <Input
                                icon="fas fa-calendar-alt"
                                label="Data Fim"
                                onChange={(e) => setEndDate(e.target.value)}
                                type="date"
                                value={endDate}
                                min={startDate}
                                placeholder=""
                                required={true}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>
                                    {error.start_date}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-4">
                            <NumberFormat
                                label="Valor"
                                icon="fas fa-dollar-sign"
                                name="valor"
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setValue(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={value}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 100000000
                                    );
                                }}
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>{error.value}</p>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <TextArea
                                rows="6"
                                label="Descrição do Serviço / Informações do Cliente"
                                value={description}
                                maxLength="254"
                                onChange={(e) => setDescription(e.target.value)}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                            />
                            {error ? (
                                <p style={{ color: 'red' }}>
                                    {error.description}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-md-6">
                            <label>Anexo da Proposta</label>
                            <ContentUpload>
                                <>
                                    <Upload
                                        disabled={
                                            props.match.params.action === 'edit'
                                                ? true
                                                : props.match.params.action ===
                                                  'view'
                                                ? true
                                                : false
                                        }
                                        onUpload={handleUpload}
                                    />
                                    {!!uploadedFiles.length && (
                                        <FileList
                                            action={props.match.params.action}
                                            toEdit={
                                                props.match.params.action ===
                                                'edit'
                                                    ? `/project/${props.match.params.id}/edit`
                                                    : ''
                                            }
                                            toCadastro="new_project"
                                            files={uploadedFiles}
                                            onDelete={handleDeleteAttachment}
                                        />
                                    )}
                                </>
                            </ContentUpload>
                        </div>
                    </div>
                    <div className="card-header">
                        <h3 className="card-title">Margens de Apuração *</h3>
                    </div>
                    <div className="row mt-3">
                        <div className={`col-md-3`}>
                            <NumberFormat
                                icon="fas fa-money-bill-wave"
                                label="Impostos *"
                                name="validity"
                                required={true}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setImposto(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={imposto}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 100
                                    );
                                }}
                            />
                        </div>
                        <div className={`col-md-3`}>
                            <NumberFormat
                                icon="fas fa-money-bill-wave"
                                label="Margem Administrativa *"
                                name="validity"
                                required={true}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setMargemAdm(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={margemAdm}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 100
                                    );
                                }}
                            />
                        </div>
                        <div className={`col-md-3`}>
                            <NumberFormat
                                icon="fas fa-money-bill-wave"
                                label="Margem Líquida *"
                                name="validity"
                                required={true}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setMargemLqd(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={margemLqd}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 100
                                    );
                                }}
                            />
                        </div>
                        <div className={`col-md-3`}>
                            <NumberFormat
                                icon="fas fa-money-bill-wave"
                                label="Margem Adicional *"
                                name="validity"
                                required={true}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                placeholder=""
                                customInput={Input}
                                onChange={(e) =>
                                    setMargemAd(
                                        parseFloat(
                                            e.target.value
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.')
                                        )
                                    )
                                }
                                value={margemAd}
                                disabled={
                                    props.match.params.action === 'view'
                                        ? true
                                        : false
                                }
                                isAllowed={(values) => {
                                    const { floatValue, formattedValue } =
                                        values;
                                    return (
                                        formattedValue === '' ||
                                        floatValue <= 100
                                    );
                                }}
                            />

                            {id_type.value === 1 ? (
                                <TextArea
                                    rows="6"
                                    required={false}
                                    label="Descrição"
                                    value={descriptionAdditionalMargin}
                                    maxLength="255"
                                    onChange={(e) =>
                                        setDescriptionAdditionalMargin(
                                            e.target.value
                                        )
                                    }
                                    disabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    {/* {
                            props.match.params.action &&
                            <div className="row">
                                <div className="col-md-12">
                                    <p style={{height: '40px'}}>
                                        <span style={{height: '50px', fontWeight: 700}}> 
                                            <a href="#/" onClick={(e) => {dispatch({type:'changeProjectReportExtraCharge', payload:{value: props.match.params.id, label: title}});e.preventDefault();props.history.push(`/extra_charges`);e.preventDefault()}}>Cobranças Extras</a>
                                        </span>
                                    </p>
                                </div>   

                            </div>
                        } */}
                    <br></br>
                    {props.match.params.action && (
                        <>
                            <div className="card-header">
                                <h3 className="card-title">
                                    Adição de Técnicos
                                </h3>
                            </div>
                            <div className="row mt-3 ml-3">
                                <Technical
                                    technical={technical}
                                    dedication={dedicationProject}
                                    changeDedication={(e) => {
                                        setIdDedication(e);
                                    }}
                                    remunerations={remuneration}
                                    changeTechnical={(e) => setIdTechnician(e)}
                                    changeRemuneration={(e) =>
                                        setIdRemuneration(e)
                                    }
                                    changeValue={(e) =>
                                        setValueTechnical(
                                            parseFloat(
                                                e.target.value
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')
                                            )
                                        )
                                    }
                                    valueDedication={valueDedication}
                                    changeValueDedication={(e) =>
                                        setValueDedication(
                                            parseInt(e.target.value)
                                        )
                                    }
                                    id_technical={id_technician}
                                    id_dedication={id_dedication}
                                    id_remuneration={id_remuneration}
                                    value_technical={value_technical}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                    onClick={postTechnical}
                                    errorTechnical={
                                        errorsTechnical ? (
                                            <p style={{ color: 'red' }}>
                                                {errorsTechnical.id_technical}
                                            </p>
                                        ) : (
                                            ''
                                        )
                                    }
                                    errorDedication={
                                        errorsTechnical ? (
                                            <p style={{ color: 'red' }}>
                                                {errorsTechnical.id_dedication}
                                            </p>
                                        ) : (
                                            ''
                                        )
                                    }
                                    errorRemuneration={
                                        errorsTechnical ? (
                                            <p style={{ color: 'red' }}>
                                                {
                                                    errorsTechnical.id_remuneration
                                                }
                                            </p>
                                        ) : (
                                            ''
                                        )
                                    }
                                    errorValueTechnical={
                                        errorsTechnical ? (
                                            <p style={{ color: 'red' }}>
                                                {
                                                    errorsTechnical.value_technical
                                                }
                                            </p>
                                        ) : (
                                            ''
                                        )
                                    }
                                    location={window.location}
                                    dataAlocacao={dataAlocacao}
                                    changeDataAlocacao={(e) =>
                                        setDataAlocacao(e.target.value)
                                    }
                                    startDate={startDate}
                                />
                            </div>
                            {errorSubmitTechnical ? (
                                <Alert type="danger">
                                    {errorSubmitTechnical}
                                </Alert>
                            ) : (
                                ''
                            )}
                            {successSubmitTechnical ? (
                                <Alert type="success">
                                    {successSubmitTechnical}
                                </Alert>
                            ) : (
                                ''
                            )}
                            <TableTechnical>{renderTechnical()}</TableTechnical>
                        </>
                    )}
                    <br></br>
                    <div className="text-right">
                        <button
                            style={{ marginRight: '10px' }}
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => history.goBack()}
                        >
                            Voltar
                        </button>

                        {props.match.params.action === 'view' &&
                        id_type.value !== 1 ? (
                            <Link
                                style={{
                                    cursor:
                                        role.indexOf(1) < 0 &&
                                        role.indexOf(8) < 0 &&
                                        role.indexOf(3) < 0 &&
                                        role.indexOf(6) < 0 &&
                                        role.indexOf(9) < 0
                                            ? 'not-allowed'
                                            : '',
                                    color:
                                        role.indexOf(1) < 0 &&
                                        role.indexOf(8) < 0 &&
                                        role.indexOf(3) < 0 &&
                                        role.indexOf(6) < 0 &&
                                        role.indexOf(9) < 0
                                            ? '#fff'
                                            : '',
                                    backgroundColor:
                                        role.indexOf(1) < 0 &&
                                        role.indexOf(8) < 0 &&
                                        role.indexOf(3) < 0 &&
                                        role.indexOf(6) < 0 &&
                                        role.indexOf(9) < 0
                                            ? '#007bff'
                                            : '',
                                    borderColor:
                                        role.indexOf(1) < 0 &&
                                        role.indexOf(8) < 0 &&
                                        role.indexOf(3) < 0 &&
                                        role.indexOf(6) < 0 &&
                                        role.indexOf(9) < 0
                                            ? '#007bff'
                                            : '',
                                    opacity:
                                        role.indexOf(1) < 0 &&
                                        role.indexOf(8) < 0 &&
                                        role.indexOf(3) < 0 &&
                                        role.indexOf(6) < 0 &&
                                        role.indexOf(9) < 0
                                            ? 0.65
                                            : '',
                                    boxShadow:
                                        role.indexOf(1) < 0 &&
                                        role.indexOf(8) < 0 &&
                                        role.indexOf(3) < 0 &&
                                        role.indexOf(6) < 0 &&
                                        role.indexOf(9) < 0
                                            ? 'none'
                                            : '',
                                }}
                                onClick={
                                    role.indexOf(1) < 0 &&
                                    role.indexOf(3) < 0 &&
                                    role.indexOf(6) < 0 &&
                                    role.indexOf(8) < 0 &&
                                    role.indexOf(9) < 0
                                        ? (e) => e.preventDefault()
                                        : ''
                                }
                                to={
                                    id_type.value === 1
                                        ? `/projects`
                                        : `/project/${props.match.params.id}/edit`
                                }
                                className="btn btn-primary"
                            >
                                Editar
                            </Link>
                        ) : id_type.value !== 1 ? (
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={id_type.value === 1}
                            >
                                Salvar
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={true}
                            >
                                {props.match.params.action === 'view'
                                    ? 'Editar'
                                    : 'Salvar'}
                            </button>
                        )}
                    </div>
                </Form>
            </div>
            {/* </StylePage> */}
            {/* <Footer /> */}
            {/* </div> */}
        </>
    );
}
