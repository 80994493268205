import React from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import Input from '../../../Forms/Input';

export default function Technical(props) {
    return (
        <>
            <div className="col-md-2">
                <label>Técnico</label>
                <Select
                    value={props.id_technical}
                    label="Single select"
                    options={props.technical}
                    onChange={props.changeTechnical}
                    isDisabled={props.isDisabled}
                />
                <div className="mt-3">{props.errorTechnical}</div>
            </div>
            <div className="col-md-2">
                <label>Tipo de dedicação</label>
                <Select
                    value={props.id_dedication}
                    label="Single select"
                    options={props.dedication}
                    onChange={props.changeDedication}
                    isDisabled={props.isDisabled}
                />
                <div className="mt-3">{props.errorDedication}</div>
            </div>
            <div className="col-md-2">
                <Input
                    icon="fas fa-calendar-alt"
                    label="Data da alocação"
                    onChange={props.changeDataAlocacao}
                    type="date"
                    value={props.dataAlocacao}
                    min={props.startDate}
                    placeholder=""
                    // required={true}
                    disabled={props.isDisabled}
                />
                <div className="mt-3">{props.errordataAlocacao}</div>
            </div>
            <div className="col-md-2">
                <NumberFormat
                    icon="fas fa-user-tie"
                    label="Dedicação (Dias)"
                    name="dedicacao"
                    decimalScale={0}
                    placeholder=""
                    customInput={Input}
                    allowNegative={false}
                    onChange={props.changeValueDedication}
                    value={props.valueDedication}
                    // required={true}
                    disabled={props.isDisabled}
                />
                {/* {props.errorValueTechnical} */}
            </div>
            <div className="col-md-2">
                <label>Remuneração RMA</label>
                <Select
                    value={props.id_remuneration}
                    label="Single select"
                    options={props.remunerations}
                    onChange={props.changeRemuneration}
                    isDisabled={props.isDisabled}
                />
                <div className="mt-3">{props.errorRemuneration}</div>
            </div>
            <div className="col-md-1">
                <NumberFormat
                    icon="fas fa-dollar-sign"
                    label="Valor RMA"
                    name="valor"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    placeholder=""
                    customInput={Input}
                    onChange={props.changeValue}
                    value={props.value_technical}
                    disabled={props.isDisabled}
                />
                {props.errorValueTechnical}
            </div>
            <div className="col-md-1">
                <div
                    className="input-group mb-3"
                    style={{ paddingTop: '30px' }}
                >
                    <button
                        disabled={props.isDisabled}
                        type="button"
                        className="btn btn-primary"
                        onClick={props.onClick}
                    >
                        <span className="fas fa-plus"></span>
                    </button>
                </div>
            </div>
        </>
    );
}
