import React from 'react'
import "react-toggle/style.css"

export default function Technical({ technical, formatReal, handleDeleteTechnical, isDisabled }) {
    const technicals = technical || []
    
    return technicals.map((desc) =>(
        <tr key={desc.id}>
            <td className="text-center">{desc.nameTechnical}</td>
            <td className="text-center">{desc.nameTypeDedication}</td>
            <td className="text-center">{desc.dateAlocacao ? desc.dateAlocacao.split("-")[2] + "/" + desc.dateAlocacao.split("-")[1] + "/" + desc.dateAlocacao.split("-")[0] : ''}</td>
            <td className="text-center">{desc.dedication ? parseInt(`${desc.dedication}`) : 0}</td>
            {/* {`${perfis}`.indexOf(1)>=0 ||`${perfis}`.indexOf(2)>=0 ||`${perfis}`.indexOf(3)>=0 ||`${perfis}`.indexOf(4)>=0 ||`${perfis}`.indexOf(5)>=0 ||`${perfis}`.indexOf(6)>=0 ||`${perfis}`.indexOf(8)>=0 ?<td className="text-center">{desc.descricao}</td> : <span></span>} */}
            <td className="text-center">{desc.nameRemuneration}</td>
            <td className="text-center">R${formatReal(parseFloat(`${desc.value}`))}</td>
            <td className="text-center">
                <button disabled={isDisabled} type="button" className="btn btn-danger" onClick={() => handleDeleteTechnical(desc.id)}><span className="fas fa-minus"></span></button>
            </td>
        </tr>
        
    ))  
}
