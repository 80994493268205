/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Select from 'react-select';
import StylePage from '../../../Style Page/StylePage';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ContentUpload } from '../../../Upload/styles';
import Upload from '../../../Upload/upload';
import FileList from '../../../FileList/FileList';
import { useSelector } from 'react-redux';
import {
    delAttachment,
    getCity,
    getResource,
    getViewProjectAmendment,
    postProjectAmendment,
    putProjectAmendment,
    getViewPerProject,
    getAreaManager,
} from '../../../Services/projectAmendment';
import TableTechnicalProjectAmendment from './TableTechnicalProjectAmendment';
import Technical from './Technical';
import { useHistory, Link } from 'react-router-dom';
import Alert from '../../../Alerts/Alert';
import { Content } from '../../../LoadingStyle/StyleLoading';
import ReactLoading from 'react-loading';
import TableProducts from './TableProducts';
import Products from './Products';
import RadioMaterial from '../../../Forms/RadioMaterial';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableProdutivity from './TableProdutivity';
import Produtivity from './Produtivity';
import makeAnimated from 'react-select/animated';
import TextArea from '../../../Forms/TextArea';

function ProjectAmendment(props) {
    function formatReal(desc) {
        let tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    function formatNumeric(desc) {
        let tmp = `${desc.toFixed(1)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }

    let history = useHistory();
    const margemRef = useRef(null);
    const produtoRef = useRef(null);
    const tecnicoRef = useRef(null);
    const culturaRef = useRef(null);
    const selectProdutoRef = useRef(null);
    const selectClientRef = useRef(null);
    const selectTypeProjectAmendmentRef = useHistory();
    const selectMainActivityRef = useRef(null);
    const [reembolsavel, setReembolsavel] = useState('Não');
    const selectAreaRef = useRef(null);
    const selectRemunerationRef = useRef(null);
    const selectTechnicalRef = useRef(null);
    const selectProjectRef = useRef(null);
    const selectCoordinateRef = useRef(null);
    const selectTypeChargeRef = useRef(null);
    const selectTypeMotiveRef = useRef(null);
    const selectStateRef = useRef(null);
    const selectCityRef = useRef(null);
    const selectAgentSaleRef = useRef(null);
    const selectOriginOfSaleRef = useRef(null);
    const selectQtdParcelRef = useRef(null);
    const selectTypeDedicationRef = useRef(null);
    const selectManagerRef = useRef(null);
    const [created_by, setCreated] = useState('');
    const [id_status, setIdStatus] = useState('');
    const [centroCusto, setCentroCusto] = useState(null);
    const id_user = useSelector((state) => state.auth.user.id);
    const [loading, setLoading] = useState(false);
    // const [ error, setError ]                                     = useState([])
    const errorTechnical = '';
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [alertCultura, setAlertCultura] = useState('');
    const [alertProduto, setAlertProduto] = useState('');
    const [alertTecnico, setAlertTecnico] = useState('');
    const [alertMargem, setAlertMargem] = useState('');
    // const [ phone, setPhone ]                                     = useState('')
    const [acre, setAcre] = useState('');
    const [optionsState, setOptionsState] = useState([]);
    const [idState, setIdState] = useState('');
    const [dateBase, setDateBase] = useState('');
    const [dateAlocacao, setDateAlocacao] = useState('');
    const [idCity, setIdCity] = useState('');
    const [optionsCity, setOptionsCity] = useState([]);
    // const [ idTypeSalesRecord, setIdTypeSalesRecord ]             = useState()
    const [idTypeProjectAmendment, setIdTypeProjectAmendment] = useState();
    // const [ optionsTypeSalesRecord, setOptionsTypeSalesRecord ]   = useState([])
    const [agentSale, setAgentSale] = useState('');
    const [billingProperty, setBillingProperty] = useState('');
    const [optionsAgentSale, setOptionsAgentSale] = useState([]);
    const [originOfSale, setOriginOfSale] = useState('');
    const [optionsProduct, setOptionsProduct] = useState([]);
    const [idProduct, setIdProduct] = useState('');
    const [optionsCulture, setOptionsCulture] = useState([]);
    const [idCulture, setIdCulture] = useState('');
    const [product, setProduct] = useState([]);
    const [produtivity, setProdutivity] = useState([]);
    const [optionsOriginOfSale, setOptionsOriginOfSale] = useState([]);
    const [optionsProject, setOptionsProject] = useState([]);
    const [optionsActiveProject, setOptionsActiveProject] = useState([]);
    const [optionsInactiveProject, setOptionsInactiveProject] = useState([]);
    const [optionsTypeProjectAmendment, setOptionsTypeProjectAmendment] =
        useState([]);
    const [idArea, setIdArea] = useState('');
    const [number, setNumber] = useState('');
    const [idProject, setIdProject] = useState('');
    const [idCoordinator, setIdCoordinator] = useState('');
    const [description, setDescription] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [valueContract, setValueContract] = useState(0);
    const [valueProduct, setValueProduct] = useState('');
    const [valueCulture, setValueCulture] = useState('');
    const [typeCharge, setTypeCharge] = useState('');
    const [typeMotive, setTypeMotive] = useState('');
    const [qtdParcel, setQtdParcel] = useState('');
    const [validity, setValidity] = useState('');
    const [dateSale, setDateSale] = useState('');
    const [dateFirstVisit, setDateFirstVisit] = useState('');
    const [imposto, setImposto] = useState(16);
    const [margemAdm, setMargemAdm] = useState(10);
    const [margemLqd, setMargemLqd] = useState(20);
    const [margemAd, setMargemAd] = useState(0);
    const [optionsTechnical, setOptionsTechnical] = useState([]);
    const [idMainActivity, setIdMainActivity] = useState('');
    const [optionsArea, setOptionsArea] = useState([]);
    const [optionsCoordinator, setOptionsCoordinator] = useState([]);
    const [optionsRemuneration, setOptionsRemuneration] = useState([]);
    const [optionsRemunerationNotMonthly, setOptionsRemunerationNotMonthly] =
        useState([]);
    const [typeProject, setTypeProject] = useState('');
    const [optionsMotive, setOptionsMotive] = useState([]);
    const [technical, setTechnical] = useState([]);
    const [removedTechnical, setRemovedTechnical] = useState([]);
    const [idTechnical, setIdTechnical] = useState([]);
    const [idRemuneration, setIdRemuneration] = useState([]);
    const [valueTechnical, setValueTechnical] = useState('');
    const [idTypeDedication, setIdTypeDedication] = useState([]);
    const [optionsTypeDedication, setOptionsTypeDedication] = useState([]);
    const [managers, setManagers] = useState([]);
    const [idManager, setIdManager] = useState('');
    // const [ optionsQtdParcel, setOptionsQtdParcel ]               = useState([])
    const [dedication, setDedication] = useState('');
    const [error, setError] = useState('');
    const [initialData, setInitialData] = useState([]);
    const optionsTypeCharge = [
        { label: 'Única', value: 'Única' },
        { label: 'Mensal', value: 'Mensal' },
        { label: 'Variável', value: 'Variável' },
    ];
    const [descriptionAdditionalMargin, setDescriptionAdditionalMargin] =
        useState('');

    const animatedComponents = makeAnimated();
    const [customers, setCustomers] = useState([]);
    const [id_client, setIdClient] = useState([]);
    const date = new Date();
    let maxDate = new Date();
    maxDate = new Date(date.setMonth(date.getMonth() + 4));
    maxDate = maxDate.toISOString().split('T')[0];
    // console.log(maxDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const today = getCurrentDateFormatted();
    const [descriptionValue, setDescriptionValue] = useState('');
    const [tipoServico, setTipoServico] = useState('');

    const perfis = useSelector((state) => state.auth.user.perfis) || [];

    function getCurrentDateFormatted() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    }

    function optionsQtdParcela(valorContrato) {
        const qtdparcel = [
            { label: `2x de  R$${formatReal(valorContrato / 2)}`, value: 2 },
            { label: `3x de  R$${formatReal(valorContrato / 3)}`, value: 3 },
            { label: `4x de  R$${formatReal(valorContrato / 4)}`, value: 4 },
            { label: `5x de  R$${formatReal(valorContrato / 5)}`, value: 5 },
            { label: `6x de  R$${formatReal(valorContrato / 6)}`, value: 6 },
            { label: `7x de  R$${formatReal(valorContrato / 7)}`, value: 7 },
            { label: `8x de  R$${formatReal(valorContrato / 8)}`, value: 8 },
            { label: `9x de  R$${formatReal(valorContrato / 9)}`, value: 9 },
            { label: `10x de  R$${formatReal(valorContrato / 10)}`, value: 10 },
            { label: `11x de  R$${formatReal(valorContrato / 11)}`, value: 11 },
            { label: `12x de  R$${formatReal(valorContrato / 12)}`, value: 12 },
            { label: `18x de  R$${formatReal(valorContrato / 18)}`, value: 18 },
            { label: `24x de  R$${formatReal(valorContrato / 24)}`, value: 24 },
            { label: `36x de  R$${formatReal(valorContrato / 36)}`, value: 36 },
            { label: `48x de  R$${formatReal(valorContrato / 48)}`, value: 48 },
        ];
        return qtdparcel;
    }

    useEffect(() => {
        getDataAll();
        viewPerId();
    }, []);

    useEffect(() => {
        viewPerProject();
    }, [idProject]);

    useEffect(() => {
        city(idState.value);
    }, [idState]);

    useEffect(() => {
        optionsQtdParcela(valueContract);
        setQtdParcel(
            optionsQtdParcela(valueContract).filter(
                (desc) => parseInt(desc.value) === parseInt(qtdParcel.value)
            )[0] || ''
        );
    }, [qtdParcel.value, valueContract]);

    const handleChangeClient = (newValue: any, actionMeta: any) => {
        setIdClient(newValue);
    };

    const handleChangeArea = (e) => {
        let areaID = e.value;
        setIdArea(e);

        getAreaManager(areaID)
            .then((resp) => {
                setIdManager(resp.data[0]);
                setManagers(
                    resp.data.map((desc) => ({
                        value: desc.value,
                        label: desc.label,
                    }))
                );
            })
            .catch((err) => console.log(err.data));
    };

    //Função para restaurar estado do form quando o Tipo de Alteração de Projeto for alterado
    const resetAllData = () => {
        if (!idTypeProjectAmendment || !idProject) {
            return null;
        }
        // console.log(initialData);
        let valorContrato = '';
        let qtd_parcel = '';
        valorContrato = initialData.ProjectAmendment[0].valor_contrato
            ? initialData.ProjectAmendment[0].valor_contrato
            : 0;
        // qtd_parcel = parseInt(initialData.ProjectAmendment[0].quantidade_parcela);
        qtd_parcel = initialData.ProjectAmendment[0].quantidade_parcela
            ? parseInt(initialData.ProjectAmendment[0].quantidade_parcela)
            : initialData.ProjectAmendment[0].quantidade_parcela;
        setIdArea({
            value: initialData.ProjectAmendment[0].id_area_projeto,
            label: initialData.ProjectAmendment[0].area_projeto,
        });
        setAcre(initialData.ProjectAmendment[0].hectares_propriedade);
        setNumber(initialData.ProjectAmendment[0].quantitativo);
        setIdCoordinator({
            value: initialData.ProjectAmendment[0].id_coordenador,
            label: initialData.ProjectAmendment[0].coordenador,
        });
        setAgentSale({
            value: initialData.ProjectAmendment[0].id_agente_venda,
            label: initialData.ProjectAmendment[0].agente_venda,
        });
        setOriginOfSale({
            value: initialData.ProjectAmendment[0].id_origem_venda,
            label: initialData.ProjectAmendment[0].origem_venda,
        });
        // setIdState({
        //     value: initialData.ProjectAmendment[0].id_state,
        //     label: initialData.ProjectAmendment[0].state,
        // });
        // setIdCity({
        //     value: initialData.ProjectAmendment[0].id_city,
        //     label: initialData.ProjectAmendment[0].city,
        // });
        setDescription(initialData.ProjectAmendment[0].descricao_servico);
        setValueContract(initialData.ProjectAmendment[0].valor_contrato);
        setImposto(initialData.ProjectAmendment[0].imposto);
        setCentroCusto(
            initialData.ProjectAmendment[0].centro_custo
                ? initialData.ProjectAmendment[0].centro_custo
                : ''
        );
        setMargemAdm(initialData.ProjectAmendment[0].margemAdm);
        setMargemLqd(initialData.ProjectAmendment[0].margemLqd);
        setBillingProperty(initialData.ProjectAmendment[0].billing_property);
        setIdMainActivity({
            value: initialData.ProjectAmendment[0].id_main_activity,
            label: initialData.ProjectAmendment[0].main_activity,
        });
        setIdClient(
            initialData.client.map((desc) => ({
                value: desc.id_cliente,
                label: desc.cliente,
            }))
        );
        setMargemAd(initialData.ProjectAmendment[0].margemAd);
        setTypeCharge(
            initialData.ProjectAmendment[0].tipo_cobranca !== null && {
                value: optionsTypeCharge.filter(
                    (desc) =>
                        desc.value ===
                        initialData.ProjectAmendment[0].tipo_cobranca
                )[0].value,
                label: optionsTypeCharge.filter(
                    (desc) =>
                        desc.value ===
                        initialData.ProjectAmendment[0].tipo_cobranca
                )[0].label,
            }
        );
        setValidity(initialData.ProjectAmendment[0].periodo_vigencia_meses);
        setDateFirstVisit(initialData.ProjectAmendment[0].data_primeira_visita);
        setDateSale(initialData.ProjectAmendment[0].data_venda);
        setCreated(initialData.ProjectAmendment[0].created_by);
        setReembolsavel(
            parseInt(initialData.ProjectAmendment[0].reembolsavel) === 1
                ? 'Sim'
                : 'Não'
        );
        setIdStatus(initialData.ProjectAmendment[0].id_status);
        setProduct(
            initialData.product.map((desc) => ({
                id: uniqueId(),
                idProduct: desc.id_produto,
                nameProduct: desc.produto,
                value: desc.valor,
            }))
        );
        setProdutivity(
            initialData.produtivity.map((desc) => ({
                id: uniqueId(),
                idCulture: desc.id_cultura,
                nameCulture: desc.cultura,
                value: desc.valor,
            }))
        );
        setTechnical(
            initialData.technical.map((desc) => ({
                id: uniqueId(),
                idTechnical: desc.id_user,
                nameTechnical: desc.user_name,
                idRemuneration: desc.tipo_id,
                nameRemuneration: desc.remuneracao,
                value: desc.valor,
                dedication: desc.dedicacao,
                dateAlocacao: desc.data_alocacao,
                idTypeDedication: desc.id_tipo_dedicacao,
                nameTypeDedication: desc.nome_tipo_dedicacao,
                new: false,
            }))
        );
        setUploadedFiles(
            initialData.attachment.map((file) => ({
                id: file.id,
                name: file.nome,
                readableSize: filesize(file.size),
                realSize: file.size,
                uploaded: true,
                url: file.link,
                type: 2,
            }))
        );
        // isEdit(resp.data.ProjectAmendment[0].created_by,resp.data.ProjectAmendment[0].id_status)
    };

    function isEdit(created, status) {
        if (props.match.params.action === 'edit') {
            if (perfis[0] === 1 || perfis[0] === 6) {
            } else if (
                parseInt(created) !== parseInt(id_user) ||
                parseInt(status) !== 1
            ) {
                props.history.push(
                    `/project_amendment/${props.match.params.id}/view`
                );
            }
        }
    }

    function city(idState) {
        getCity(idState).then((resp) => {
            setOptionsCity(
                resp.data.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
        });
    }

    function getDataAll() {
        getResource().then((resp) => {
            setOptionsArea(
                resp.data.areaAtivo.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsCoordinator(
                resp.data.coordinator.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setOptionsTechnical(
                resp.data.technical.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );

            setOptionsTypeProjectAmendment(
                resp.data.type.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsRemuneration(
                resp.data.remuneration.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsRemunerationNotMonthly(
                resp.data.remuneration
                    .filter((desc) => desc.id !== 2)
                    .map((desc) => ({
                        value: desc.id,
                        label: desc.descricao,
                    }))
            );
            setOptionsTypeDedication(
                resp.data.typeDedication.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            // setOptionsTypeSalesRecord(resp.data.typeSalesRecord.map(desc => ({
            // 	value: desc.id,
            // 	label: desc.descricao
            // })))
            setOptionsState(
                resp.data.state.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setOptionsAgentSale(
                resp.data.agentSale.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setOptionsOriginOfSale(
                resp.data.originOfSale.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsProduct(
                resp.data.product.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsCulture(
                resp.data.cultureAtivo.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setOptionsProject(
                resp.data.project.map((desc) => ({
                    value: desc.id,
                    label: desc.projeto,
                }))
            );
            setOptionsActiveProject(
                resp.data.activeProject.map((desc) => ({
                    value: desc.id,
                    label: desc.projeto,
                }))
            );
            setOptionsInactiveProject(
                resp.data.inactiveProject.map((desc) => ({
                    value: desc.id,
                    label: desc.projeto,
                }))
            );
            setOptionsMotive(
                resp.data.motive.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            setCustomers(
                resp.data.customers.map((desc) => ({
                    value: desc.id,
                    label: desc.nome,
                }))
            );
        });
    }

    const changeState = (e) => {
        setIdState(e);
        setIdCity('');
    };

    const handleProdutivity = (e) => {
        e.preventDefault();

        let errorCheck = false;

        if (!idCulture || !valueCulture) {
            setAlertCultura(
                'É necessário escolher uma cultura e preencher o valor para adicionar uma nova produtividade !'
            );
            setTimeout(() => {
                setAlertCultura('');
            }, 3000);
            errorCheck = true;
            culturaRef.current.scrollIntoView();
        } else {
            setAlertCultura('');
        }

        let validate =
            produtivity.filter(
                (desc) => parseInt(desc.idCulture) === parseInt(idCulture.value)
            ) || [];
        if (validate.length > 0) {
            setAlertCultura('Não é possivel adicionar uma mesma cultura');
            setTimeout(() => {
                setAlertCultura('');
            }, 3000);
            errorCheck = true;
            culturaRef.current.scrollIntoView();
        } else {
            setAlertCultura('');
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idCulture: idCulture.value,
            nameCulture: idCulture.label,
            value: valueCulture,
        };

        setProdutivity(produtivity.concat(data));
        setIdCulture([]);
        setValueCulture('');
    };

    const handleProduct = (e) => {
        e.preventDefault();
        let errorCheck = false;
        if (!product || !valueProduct) {
            setAlertProduto(
                'É necessário escolher um produto e preencher o valor para adicionar um novo produto !'
            );
            setTimeout(() => {
                setAlertProduto('');
            }, 3000);
            errorCheck = true;
            produtoRef.current.scrollIntoView();
        } else {
            setAlertProduto('');
        }

        let validate =
            product.filter(
                (desc) => parseInt(desc.idProduct) === parseInt(idProduct.value)
            ) || [];
        if (validate.length > 0) {
            setAlertProduto('Não é possivel adicionar um mesmo produto');
            setTimeout(() => {
                setAlertProduto('');
            }, 3000);
            errorCheck = true;
            produtoRef.current.scrollIntoView();
        } else {
            setAlertProduto('');
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idProduct: idProduct.value,
            nameProduct: idProduct.label,
            value: valueProduct,
        };

        setValueContract(valueContract + valueProduct);
        setProduct(product.concat(data));
        setIdProduct([]);
        setValueProduct('');
    };

    function viewPerId() {
        let valorContrato = '';
        let qtd_parcel = '';
        props.match.params.action &&
            getViewProjectAmendment(props.match.params.id)
                .then((resp) => {
                    valorContrato = resp.data.ProjectAmendment[0].valor_contrato
                        ? resp.data.ProjectAmendment[0].valor_contrato
                        : 0;
                    // qtd_parcel = parseInt(resp.data.ProjectAmendment[0].quantidade_parcela);
                    qtd_parcel = resp.data.ProjectAmendment[0]
                        .quantidade_parcela
                        ? parseInt(
                              resp.data.ProjectAmendment[0].quantidade_parcela
                          )
                        : resp.data.ProjectAmendment[0].quantidade_parcela;
                    setIdProject({
                        value: resp.data.ProjectAmendment[0].id_projeto,
                        label: resp.data.ProjectAmendment[0].projeto,
                    });
                    handleChangeArea({
                        value: resp.data.ProjectAmendment[0].id_area_projeto,
                        label: resp.data.ProjectAmendment[0].area_projeto,
                    });
                    setAcre(resp.data.ProjectAmendment[0].hectares_propriedade);
                    setNumber(resp.data.ProjectAmendment[0].quantitativo);
                    setImposto(resp.data.ProjectAmendment[0].imposto);
                    setMargemAdm(resp.data.ProjectAmendment[0].margemAdm);
                    setMargemLqd(resp.data.ProjectAmendment[0].margemLqd);
                    setCentroCusto(
                        resp.data.ProjectAmendment[0].centro_custo
                            ? resp.data.ProjectAmendment[0].centro_custo
                            : ''
                    );
                    setMargemAd(resp.data.ProjectAmendment[0].margemAd);

                    setDescriptionAdditionalMargin(
                        resp.data.ProjectAmendment[0].margemAdDescription
                            ? resp.data.ProjectAmendment[0].margemAdDescription
                            : ''
                    );
                    setDescriptionValue(
                        resp.data.ProjectAmendment[0].descricao_parcela
                    );
                    setIdTypeProjectAmendment({
                        value: resp.data.ProjectAmendment[0].id_tipo_alteracao,
                        label: resp.data.ProjectAmendment[0]
                            .tipo_alteracao_projeto,
                    });
                    setBillingProperty(
                        resp.data.ProjectAmendment[0].billing_property
                    );
                    setIdMainActivity({
                        value: resp.data.ProjectAmendment[0].id_main_activity,
                        label: resp.data.ProjectAmendment[0].main_activity,
                    });
                    setIdCoordinator({
                        value: resp.data.ProjectAmendment[0].id_coordenador,
                        label: resp.data.ProjectAmendment[0].coordenador,
                    });
                    setAgentSale({
                        value: resp.data.ProjectAmendment[0].id_agente_venda,
                        label: resp.data.ProjectAmendment[0].agente_venda,
                    });
                    setOriginOfSale({
                        value: resp.data.ProjectAmendment[0].id_origem_venda,
                        label: resp.data.ProjectAmendment[0].origem_venda,
                    });
                    setIdState({
                        value: resp.data.ProjectAmendment[0].id_state,
                        label: resp.data.ProjectAmendment[0].state,
                    });
                    setIdCity({
                        value: resp.data.ProjectAmendment[0].id_city,
                        label: resp.data.ProjectAmendment[0].city,
                    });
                    setValueContract(
                        resp.data.ProjectAmendment[0].valor_contrato
                    );
                    setTypeCharge({
                        value: optionsTypeCharge.filter(
                            (desc) =>
                                desc.value ===
                                resp.data.ProjectAmendment[0].tipo_cobranca
                        )[0].value,
                        label: optionsTypeCharge.filter(
                            (desc) =>
                                desc.value ===
                                resp.data.ProjectAmendment[0].tipo_cobranca
                        )[0].label,
                    });
                    setValidity(
                        resp.data.ProjectAmendment[0].periodo_vigencia_meses
                    );
                    setDescription(
                        resp.data.ProjectAmendment[0].descricao_servico
                    );
                    setDateFirstVisit(
                        resp.data.ProjectAmendment[0].data_primeira_visita
                    );
                    setDateSale(resp.data.ProjectAmendment[0].data_venda);
                    setCreated(resp.data.ProjectAmendment[0].created_by);
                    setReembolsavel(
                        parseInt(resp.data.ProjectAmendment[0].reembolsavel) ===
                            1
                            ? 'Sim'
                            : 'Não'
                    );
                    setIdStatus(resp.data.ProjectAmendment[0].id_status);
                    setTypeProject(resp.data.ProjectAmendment[0].id_tipo);
                    setTipoServico(resp.data.type_service);
                    setDateBase(resp.data.ProjectAmendment[0].data_base);
                    setUploadedFiles(
                        resp.data.attachment.map((file) => ({
                            id: file.id,
                            name: file.nome,
                            readableSize: filesize(file.size),
                            realSize: file.size,
                            uploaded: true,
                            url: file.link,
                            type: 1,
                        }))
                    );
                    setProduct(
                        resp.data.product.map((desc) => ({
                            id: uniqueId(),
                            idProduct: desc.id_produto,
                            nameProduct: desc.produto,
                            value: desc.valor,
                        }))
                    );
                    setProdutivity(
                        resp.data.produtivity.map((desc) => ({
                            id: uniqueId(),
                            idCulture: desc.id_cultura,
                            nameCulture: desc.cultura,
                            value: desc.valor,
                        }))
                    );
                    setTechnical(
                        resp.data.technical.map((desc) => ({
                            id: uniqueId(),
                            idTechnical: desc.id_user,
                            nameTechnical: desc.user_name,
                            idRemuneration: desc.id_tipo_remuneracao,
                            nameRemuneration: desc.remuneracao,
                            idTypeDedication: desc.id_tipo_dedicacao,
                            nameTypeDedication: desc.nome_tipo_dedicacao,
                            value: desc.valor,
                            dedication: desc.dedicacao,
                            dateAlocacao: desc.data_alocacao,
                            new: false,
                        }))
                    );
                    isEdit(
                        resp.data.ProjectAmendment[0].created_by,
                        resp.data.ProjectAmendment[0].id_status
                    );
                    setTypeMotive({
                        value: resp.data.ProjectAmendment[0].id_motive,
                        label: resp.data.ProjectAmendment[0].descricao_motive,
                    });
                    resp.data.client
                        ? setIdClient(
                              resp.data.client.map((desc) => ({
                                  value: desc.id_cliente,
                                  label: desc.cliente,
                              }))
                          )
                        : setIdClient([]);
                })
                .then(() => {
                    let optionsParcel = optionsQtdParcela(valorContrato);
                    // console.log(qtd_parcel);
                    qtd_parcel !== 1 &&
                        qtd_parcel != null &&
                        setQtdParcel({
                            value: optionsParcel.filter(
                                (desc) => desc.value === qtd_parcel
                            )[0].value,
                            label: optionsParcel.filter(
                                (desc) => desc.value === qtd_parcel
                            )[0].label,
                        });
                });
    }

    function viewPerProject() {
        let valorContrato = '';
        let qtd_parcel = '';
        idProject &&
            !props.match.params.action &&
            getViewPerProject(idProject.value)
                .then((resp) => {
                    setInitialData(resp.data);
                    valorContrato = resp.data.ProjectAmendment[0].valor_contrato
                        ? resp.data.ProjectAmendment[0].valor_contrato
                        : 0;
                    // qtd_parcel = parseInt(resp.data.ProjectAmendment[0].quantidade_parcela);
                    qtd_parcel = resp.data.ProjectAmendment[0]
                        .quantidade_parcela
                        ? parseInt(
                              resp.data.ProjectAmendment[0].quantidade_parcela
                          )
                        : resp.data.ProjectAmendment[0].quantidade_parcela;
                    handleChangeArea({
                        value: resp.data.ProjectAmendment[0].id_area_projeto,
                        label: resp.data.ProjectAmendment[0].area_projeto,
                    });
                    setAcre(resp.data.ProjectAmendment[0].hectares_propriedade);
                    setNumber(resp.data.ProjectAmendment[0].quantitativo);
                    setIdCoordinator({
                        value: resp.data.ProjectAmendment[0].id_coordenador,
                        label: resp.data.ProjectAmendment[0].coordenador,
                    });
                    setAgentSale({
                        value: resp.data.ProjectAmendment[0].id_agente_venda,
                        label: resp.data.ProjectAmendment[0].agente_venda,
                    });
                    setOriginOfSale({
                        value: resp.data.ProjectAmendment[0].id_origem_venda,
                        label: resp.data.ProjectAmendment[0].origem_venda,
                    });
                    setIdState({
                        value: resp.data.ProjectAmendment[0].id_state,
                        label: resp.data.ProjectAmendment[0].state,
                    });
                    setIdCity({
                        value: resp.data.ProjectAmendment[0].id_city,
                        label: resp.data.ProjectAmendment[0].city,
                    });
                    setDescription(
                        resp.data.ProjectAmendment[0].descricao_servico
                    );
                    setValueContract(
                        resp.data.ProjectAmendment[0].valor_contrato
                    );
                    setImposto(resp.data.ProjectAmendment[0].imposto);
                    setCentroCusto(
                        resp.data.ProjectAmendment[0].centro_custo
                            ? resp.data.ProjectAmendment[0].centro_custo
                            : ''
                    );
                    setMargemAdm(resp.data.ProjectAmendment[0].margemAdm);
                    setDescriptionAdditionalMargin(
                        resp.data.ProjectAmendment[0].margemAdDescription
                            ? resp.data.ProjectAmendment[0].margemAdDescription
                            : ''
                    );
                    setMargemLqd(resp.data.ProjectAmendment[0].margemLqd);
                    setBillingProperty(
                        resp.data.ProjectAmendment[0].billing_property
                    );
                    setIdMainActivity({
                        value: resp.data.ProjectAmendment[0].id_main_activity,
                        label: resp.data.ProjectAmendment[0].main_activity,
                    });
                    setMargemAd(resp.data.ProjectAmendment[0].margemAd);
                    setTypeCharge(
                        resp.data.ProjectAmendment[0].tipo_cobranca !==
                            null && {
                            value: optionsTypeCharge.filter(
                                (desc) =>
                                    desc.value ===
                                    resp.data.ProjectAmendment[0].tipo_cobranca
                            )[0].value,
                            label: optionsTypeCharge.filter(
                                (desc) =>
                                    desc.value ===
                                    resp.data.ProjectAmendment[0].tipo_cobranca
                            )[0].label,
                        }
                    );
                    setIdClient(
                        resp.data.client.map((desc) => ({
                            value: desc.id_cliente,
                            label: desc.cliente,
                        }))
                    );
                    setValidity(
                        resp.data.ProjectAmendment[0].periodo_vigencia_meses
                    );
                    setDateFirstVisit(
                        resp.data.ProjectAmendment[0].data_primeira_visita
                    );
                    setDateSale(resp.data.ProjectAmendment[0].data_venda);
                    setCreated(resp.data.ProjectAmendment[0].created_by);
                    setReembolsavel(
                        parseInt(resp.data.ProjectAmendment[0].reembolsavel) ===
                            1
                            ? 'Sim'
                            : 'Não'
                    );
                    setIdStatus(resp.data.ProjectAmendment[0].id_status);
                    setProduct(
                        resp.data.product.map((desc) => ({
                            id: uniqueId(),
                            idProduct: desc.id_produto,
                            nameProduct: desc.produto,
                            value: desc.valor,
                        }))
                    );
                    setTypeProject(resp.data.ProjectAmendment[0].id_tipo);
                    setTipoServico(resp.data.type_service);
                    setProdutivity(
                        resp.data.produtivity.map((desc) => ({
                            id: uniqueId(),
                            idCulture: desc.id_cultura,
                            nameCulture: desc.cultura,
                            value: desc.valor,
                        }))
                    );
                    setTechnical(
                        resp.data.technical.map((desc) => ({
                            id: uniqueId(),
                            idTechnical: desc.id_user,
                            nameTechnical: desc.user_name,
                            idRemuneration: desc.tipo_id,
                            nameRemuneration: desc.remuneracao,
                            value: desc.valor,
                            idTypeDedication: desc.id_tipo_dedicacao,
                            nameTypeDedication: desc.nome_tipo_dedicacao,
                            dedication: desc.dedicacao,
                            dateAlocacao: desc.data_alocacao,
                            new: false,
                        }))
                    );
                    setUploadedFiles(
                        resp.data.attachment.map((file) => ({
                            id: file.id,
                            name: file.nome,
                            readableSize: filesize(file.size),
                            realSize: file.size,
                            uploaded: true,
                            url: file.link,
                            type: 2,
                        }))
                    );

                    // isEdit(resp.data.ProjectAmendment[0].created_by,resp.data.ProjectAmendment[0].id_status)
                })
                .then(() => {
                    if (valorContrato > 0) {
                        let optionsParcel = optionsQtdParcela(valorContrato);
                        qtd_parcel !== 1 &&
                            qtd_parcel != null &&
                            setQtdParcel({
                                value: optionsParcel.filter(
                                    (desc) => desc.value === qtd_parcel
                                )[0].value,
                                label: optionsParcel.filter(
                                    (desc) => desc.value === qtd_parcel
                                )[0].label,
                            });
                    }
                });
    }

    // const changePhone = event => {
    //     const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
    //     setPhone(number)
    // }

    const handleUpload = (files) => {
        const uploadedFile = files.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            realSize: file.size,
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
            type: 1,
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    };

    const handleDeleteAttachment = (id) => {
        const upload = uploadedFiles.filter((file) => file.id === id);
        if (upload[0].url) {
            delAttachment(id).then((resp) => {
                viewPerId(props.match.params.id);
            });
        }
        setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
    };

    const handleTechnical = (e) => {
        e.preventDefault();
        let errorCheck = false;

        let validate =
            technical.filter(
                (desc) =>
                    parseInt(desc.idTechnical) ===
                        parseInt(idTechnical.value) &&
                    parseInt(desc.idRemuneration) ===
                        parseInt(idRemuneration.value)
            ) || [];
        if (validate.length > 0) {
            setAlertTecnico(
                'Não é possivel adicionar um mesmo técnico com remunerações iguais'
            );
            setTimeout(() => {
                setAlertTecnico('');
            }, 3000);
            errorCheck = true;
            tecnicoRef.current.scrollIntoView();
        } else {
            setAlertTecnico('');
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idTechnical: idTechnical.value,
            nameTechnical: idTechnical.label,
            dateAlocacao: dateAlocacao,
            dedication: dedication,
            idRemuneration: idRemuneration.value,
            nameRemuneration: idRemuneration.label,
            idTypeDedication: idTypeDedication.value,
            nameTypeDedication: idTypeDedication.label,
            value: valueTechnical,
            new: true,
        };

        setTechnical(technical.concat(data));
        setIdTechnical([]);
        setIdRemuneration([]);
        setValueTechnical('');
        setIdTypeDedication([]);
        setDedication('');
        setDateAlocacao('');
    };

    const handleDeleteTechnical = (id) => {
        setTechnical(technical.filter((desc) => desc.id !== id));
        setRemovedTechnical(
            removedTechnical.concat(technical.filter((desc) => desc.id == id))
        );
    };

    const handleDeleteProduct = (id) => {
        let valueProduct = product.filter(
            (desc) => parseInt(desc.id) === parseInt(id)
        )[0].value;
        setValueContract(valueContract - valueProduct);
        setProduct(product.filter((desc) => desc.id !== id));
    };

    const handleDeleteProdutivity = (id) => {
        setProdutivity(produtivity.filter((desc) => desc.id !== id));
    };

    const handleForm = (e) => {
        e.preventDefault();
        let errorCheck = false;
        setLoading(true);

        if (dateBase) {
            errorCheck = dateEnd(dateBase);
        }

        if (product.length <= 0) {
            setAlertProduto(
                'Você precisa adicionar produtos na Alteração de Projeto !'
            );
            setTimeout(() => {
                setAlertProduto('');
            }, 3000);
            errorCheck = true;
            produtoRef.current.scrollIntoView();
        } else {
            setAlertProduto('');
        }

        if (produtivity.length <= 0) {
            setAlertCultura(
                'Você precisa adicionar cultura na Alteração de Projeto !'
            );
            setTimeout(() => {
                setAlertCultura('');
            }, 3000);
            errorCheck = true;
            culturaRef.current.scrollIntoView();
        } else {
            setAlertCultura('');
        }

        if (imposto + margemAdm + margemLqd + margemAd > 100) {
            setAlertMargem(
                'A soma dos valores de margens de apuração não podem passar de 100 !'
            );
            setTimeout(() => {
                setAlertMargem('');
            }, 3000);
            errorCheck = true;
            margemRef.current.scrollIntoView();
        } else {
            setAlertMargem('');
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = new FormData();
        let uploadedFile = uploadedFiles.filter((desc) => !desc.url);

        let resultado = '';
        let attachment = false;
        for (let i = 0; i < uploadedFile.length; i++) {
            if (uploadedFile[i].file) {
                data.append(`file${i}`, uploadedFile[i].file);
                resultado = i;
                attachment = true;
            }
        }

        let newClient = [];
        id_client.map((desc) => newClient.push(desc.value));
        // data.append("phone", phone)
        data.append('idProject', parseInt(idProject.value));
        data.append('billingProperty', billingProperty);
        data.append('idMainActivity', parseInt(idMainActivity.value));
        data.append('reembolsavel', reembolsavel === 'Sim' ? 1 : 0);
        data.append('idAreaProject', parseInt(idArea.value));
        data.append('city', parseInt(idCity.value));
        data.append('agentSale', parseInt(agentSale.value));
        data.append('originOfSale', parseInt(originOfSale.value));
        data.append('dateSale', dateSale);
        data.append('dateBase', dateBase);
        data.append('hectaresProperty', acre);
        data.append('numberAmount', number);
        data.append('idCoordinator', parseInt(idCoordinator.value));
        data.append('descriptionService', description);
        data.append('isAttachment', attachment);
        data.append('valueContract', valueContract);
        data.append('typeCharge', typeCharge.value);
        data.append('uploadedFiles', JSON.stringify(uploadedFiles));
        data.append(
            'amountInstallment',
            typeCharge.value === 'Única'
                ? 1
                : typeCharge.value === 'Mensal'
                ? qtdParcel.value
                : ''
        );
        data.append('client', newClient);
        data.append('validity', validity);
        data.append('dateFirstVisit', dateFirstVisit);
        data.append('technical', JSON.stringify(technical));
        data.append(
            'removedTechnical',
            removedTechnical.length > 0
                ? JSON.stringify(removedTechnical)
                : null
        );
        data.append('product', JSON.stringify(product));
        data.append('produtivity', JSON.stringify(produtivity));
        data.append('qtd', resultado);
        data.append('imposto', imposto);
        data.append('margemAdm', margemAdm);
        data.append('margemLqd', margemLqd);
        data.append('margemAd', margemAd);
        data.append('id_tipo_alteracao', idTypeProjectAmendment.value);
        data.append('id_motive', typeMotive ? typeMotive.value : '');
        data.append('cod_centro_custo', centroCusto);
        data.append('descriptionValue', descriptionValue);
        data.append('descriptionAdditionalMargin', descriptionAdditionalMargin);

        // debugger

        if (props.match.params.action === 'edit') {
            data.append('_method', 'put');
        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            putProjectAmendment(id, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setAlert('');
                    }, 3000);
                });
        } else {
            postProjectAmendment(data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        setAlert('');
                    }, 3000);
                });
        }
    };

    //Função para validar data final menor que data atual
    const dateEnd = (date) => {
        const data = new Date();
        let dia =
            `${data.getDate()}`.length == 1
                ? `0${data.getDate()}`
                : `${data.getDate()}`;
        let mes =
            `${data.getMonth() + 1}`.length == 1
                ? `0${data.getMonth() + 1}`
                : `${data.getMonth() + 1}`;
        let ano = data.getFullYear();

        if (date < `${ano}-${mes}-${dia}`) {
            setError({
                dateBase: 'A data base não pode ser menor que a data atual.',
            });

            document.getElementById('data_base').focus();

            setTimeout(() => {
                setError('');
            }, 5000);

            return true;
        }

        return false;
    };

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit'
                        ? 'Edição de Alteração de Projeto'
                        : props.match.params.action === 'view'
                        ? 'Visualização de Alteração de Projeto'
                        : 'Cadastro de Alteração de Projeto'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {success ? (
                    <div>
                        <Alert
                            type="success"
                            onCloseAlert={() => setSuccess('')}
                        >
                            {success}
                        </Alert>
                    </div>
                ) : (
                    ''
                )}
                {alert ? (
                    <div>
                        <Alert type="danger" onCloseAlert={() => setAlert('')}>
                            {alert}
                        </Alert>
                    </div>
                ) : (
                    ''
                )}
                <div
                    style={{
                        width: '100%',
                        display: loading === true ? 'none' : 'block',
                        opacity: success || alert ? 0.1 : 1,
                    }}
                >
                    <Form onSubmit={handleForm}>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label>Tipo de Alteração de Projeto</label>
                                <Select
                                    required={true}
                                    value={idTypeProjectAmendment}
                                    ref={selectTypeProjectAmendmentRef}
                                    // label="Área de Produção"
                                    options={optionsTypeProjectAmendment}
                                    onChange={(e) => {
                                        setIdTypeProjectAmendment(e);
                                        if (idProject.value) {
                                            let project =
                                                optionsInactiveProject.filter(
                                                    (desc) =>
                                                        desc.value ===
                                                        idProject.value
                                                )[0] || null;
                                            if (
                                                project &&
                                                (e.value == 1 ||
                                                    e.value == 2 ||
                                                    e.value == 3)
                                            ) {
                                                setIdProject('');
                                            }
                                        }
                                        resetAllData();
                                    }}
                                    isDisabled={
                                        props.match.params.action ? true : false
                                    }
                                    // isDisabled={true}
                                />
                                {!idTypeProjectAmendment && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idTypeProjectAmendment}
                                        onFocus={() =>
                                            selectTypeProjectAmendmentRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                            <div className="col-md-6">
                                <label>Projeto</label>
                                <Select
                                    required={true}
                                    value={idProject}
                                    ref={selectProjectRef}
                                    // label="Área de Produção"
                                    options={
                                        idTypeProjectAmendment?.value == 1 ||
                                        idTypeProjectAmendment?.value == 2 ||
                                        idTypeProjectAmendment?.value == 3
                                            ? optionsActiveProject
                                            : optionsProject
                                    }
                                    onChange={(e) => setIdProject(e)}
                                    isDisabled={
                                        props.match.params.action ||
                                        !idTypeProjectAmendment
                                            ? true
                                            : false
                                    }
                                    // isDisabled={true}
                                />
                                {!idProject && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idProject}
                                        onFocus={() =>
                                            selectProjectRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                        </div>
                        {idProject &&
                            // Tipo Encerramento Contrato
                            (idTypeProjectAmendment?.value == 1 ? (
                                <>
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            Dados do Contrato
                                        </h3>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-money-check-alt"
                                                label="Valor Total do Contrato"
                                                name="valueContract"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValueContract(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valueContract}
                                                disabled={true}
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 1000000000
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>
                                                Tipo de Cobrança Parcelas
                                            </label>
                                            <Select
                                                required={true}
                                                value={typeCharge}
                                                ref={selectTypeChargeRef}
                                                label="typeCharge"
                                                options={optionsTypeCharge}
                                                onChange={(e) =>
                                                    setTypeCharge(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view' ||
                                                    valueContract <= 0 ||
                                                    valueContract === '' ||
                                                    idTypeProjectAmendment.value ===
                                                        1 ||
                                                    idTypeProjectAmendment.value ===
                                                        2 ||
                                                    idTypeProjectAmendment.value ===
                                                        4
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!typeCharge && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={typeCharge}
                                                    onFocus={() =>
                                                        selectTypeChargeRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {typeCharge.value === 'Única' ? (
                                            <>
                                                <div className="col-md-3">
                                                    <Input
                                                        icon="fas fa-money-bill-wave"
                                                        label="Valor da Parcela"
                                                        name="valueParcel"
                                                        type="text"
                                                        // onChange={(e) => setValueContract(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                                                        value={`1x de R$${formatReal(
                                                            valueContract
                                                        )}`}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </>
                                        ) : typeCharge.value === 'Mensal' ? (
                                            <>
                                                <div className="col-md-3">
                                                    <label>
                                                        Valor das Parcelas
                                                    </label>
                                                    <Select
                                                        required={true}
                                                        value={qtdParcel}
                                                        ref={selectQtdParcelRef}
                                                        label="typeCharge"
                                                        options={optionsQtdParcela(
                                                            valueContract
                                                        )}
                                                        onChange={(e) =>
                                                            setQtdParcel(e)
                                                        }
                                                        isDisabled={
                                                            props.match.params
                                                                .action ===
                                                                'view' ||
                                                            idTypeProjectAmendment.value ===
                                                                1 ||
                                                            idTypeProjectAmendment.value ===
                                                                2 ||
                                                            idTypeProjectAmendment.value ===
                                                                4
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {!qtdParcel && (
                                                        <input
                                                            tabIndex={-1}
                                                            autoComplete="off"
                                                            style={{
                                                                opacity: 0,
                                                                width: '100%',
                                                                height: 0,
                                                                position:
                                                                    'absolute',
                                                            }}
                                                            defaultValue={
                                                                qtdParcel
                                                            }
                                                            onFocus={() =>
                                                                selectQtdParcelRef.current.focus()
                                                            }
                                                            required={true}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {typeCharge.value && (
                                            <>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <NumberFormat
                                                        icon="fas fa-money-bill-wave"
                                                        label="Período de Vigência (Meses)"
                                                        name="validity"
                                                        allowNegative={false}
                                                        fixedDecimalScale={true}
                                                        decimalScale={0}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        placeholder=""
                                                        customInput={Input}
                                                        onChange={(e) =>
                                                            setValidity(
                                                                parseFloat(
                                                                    e.target.value
                                                                        .replace(
                                                                            '.',
                                                                            ''
                                                                        )
                                                                        .replace(
                                                                            '.',
                                                                            ''
                                                                        )
                                                                        .replace(
                                                                            ',',
                                                                            '.'
                                                                        )
                                                                )
                                                            )
                                                        }
                                                        value={validity}
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                                'view' ||
                                                            idTypeProjectAmendment.value ===
                                                                1 ||
                                                            idTypeProjectAmendment.value ===
                                                                2 ||
                                                            idTypeProjectAmendment.value ===
                                                                4
                                                                ? true
                                                                : false
                                                        }
                                                        isAllowed={(values) => {
                                                            const {
                                                                floatValue,
                                                                formattedValue,
                                                            } = values;
                                                            return (
                                                                formattedValue ===
                                                                    '' ||
                                                                floatValue <= 72
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <Input
                                                        icon="fas fa-calendar"
                                                        label="Data Prevista da Primeira Visita"
                                                        name="validity"
                                                        type="date"
                                                        placeholder=""
                                                        disabled={true}
                                                        onChange={(e) =>
                                                            setDateFirstVisit(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={dateFirstVisit}
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <Input
                                                        icon="fas fa-calendar"
                                                        label="Data Base"
                                                        min={date}
                                                        max={maxDate}
                                                        name="validity"
                                                        id="data_base"
                                                        type="date"
                                                        placeholder=""
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            setDateBase(
                                                                e.target.value
                                                            )
                                                        }
                                                        // onChange={ (e) => dateEnd(e) }
                                                        value={dateBase}
                                                        required={true}
                                                    />
                                                    {error ? (
                                                        <p
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {error.dateBase}
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>
                                                Motivo de Encerramento
                                            </label>
                                            <Select
                                                required={
                                                    idTypeProjectAmendment.value ===
                                                    1
                                                        ? true
                                                        : false
                                                }
                                                value={typeMotive}
                                                ref={selectTypeMotiveRef}
                                                label="typeMotive"
                                                options={optionsMotive}
                                                onChange={(e) =>
                                                    setTypeMotive(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!typeMotive && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={typeMotive}
                                                    onFocus={() =>
                                                        selectTypeMotiveRef.current.focus()
                                                    }
                                                    required={
                                                        idTypeProjectAmendment.value ===
                                                        1
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <hr></hr>
                                    <br></br>
                                    <br></br>
                                    <div className="text-right">
                                        <div
                                            style={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                position: 'absolute',
                                                bottom: '0%',
                                                left: '-2%',
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => history.goBack()}
                                            >
                                                Voltar
                                            </button>

                                            {props.match.params.action ===
                                            'view' ? (
                                                <Link
                                                    style={{
                                                        cursor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 'not-allowed'
                                                                : '',
                                                        color:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#fff'
                                                                : '',
                                                        backgroundColor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#007bff'
                                                                : '',
                                                        borderColor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#007bff'
                                                                : '',
                                                        opacity:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 0.65
                                                                : '',
                                                        boxShadow:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 'none'
                                                                : '',
                                                    }}
                                                    onClick={
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? (e) =>
                                                                  e.preventDefault()
                                                            : ''
                                                    }
                                                    to={`/project_amendment/${props.match.params.id}/edit`}
                                                    className="btn btn-primary"
                                                >
                                                    Editar
                                                </Link>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Salvar
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : idTypeProjectAmendment?.value == 2 ? (
                                <>
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            Dados do Contrato
                                        </h3>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-money-check-alt"
                                                label="Valor Total do Contrato"
                                                name="valueContract"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValueContract(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valueContract}
                                                disabled={true}
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 1000000000
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>
                                                Tipo de Cobrança Parcelas
                                            </label>
                                            <Select
                                                required={true}
                                                value={typeCharge}
                                                ref={selectTypeChargeRef}
                                                label="typeCharge"
                                                options={optionsTypeCharge}
                                                onChange={(e) =>
                                                    setTypeCharge(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view' ||
                                                    valueContract <= 0 ||
                                                    valueContract === '' ||
                                                    idTypeProjectAmendment.value ===
                                                        1 ||
                                                    idTypeProjectAmendment.value ===
                                                        2 ||
                                                    idTypeProjectAmendment.value ===
                                                        4
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!typeCharge && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={typeCharge}
                                                    onFocus={() =>
                                                        selectTypeChargeRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {typeCharge.value === 'Única' ? (
                                            <>
                                                <div className="col-md-3">
                                                    <Input
                                                        icon="fas fa-money-bill-wave"
                                                        label="Valor da Parcela"
                                                        name="valueParcel"
                                                        type="text"
                                                        // onChange={(e) => setValueContract(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                                                        value={`1x de R$${formatReal(
                                                            valueContract
                                                        )}`}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </>
                                        ) : typeCharge.value === 'Mensal' ? (
                                            <>
                                                <div className="col-md-3">
                                                    <label>
                                                        Valor das Parcelas
                                                    </label>
                                                    <Select
                                                        required={true}
                                                        value={qtdParcel}
                                                        ref={selectQtdParcelRef}
                                                        label="typeCharge"
                                                        options={optionsQtdParcela(
                                                            valueContract
                                                        )}
                                                        onChange={(e) =>
                                                            setQtdParcel(e)
                                                        }
                                                        isDisabled={
                                                            props.match.params
                                                                .action ===
                                                                'view' ||
                                                            idTypeProjectAmendment.value ===
                                                                1 ||
                                                            idTypeProjectAmendment.value ===
                                                                2 ||
                                                            idTypeProjectAmendment.value ===
                                                                4
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {!qtdParcel && (
                                                        <input
                                                            tabIndex={-1}
                                                            autoComplete="off"
                                                            style={{
                                                                opacity: 0,
                                                                width: '100%',
                                                                height: 0,
                                                                position:
                                                                    'absolute',
                                                            }}
                                                            defaultValue={
                                                                qtdParcel
                                                            }
                                                            onFocus={() =>
                                                                selectQtdParcelRef.current.focus()
                                                            }
                                                            required={true}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {typeCharge.value && (
                                            <>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <NumberFormat
                                                        icon="fas fa-money-bill-wave"
                                                        label="Período de Vigência (Meses)"
                                                        name="validity"
                                                        allowNegative={false}
                                                        fixedDecimalScale={true}
                                                        decimalScale={0}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        placeholder=""
                                                        customInput={Input}
                                                        onChange={(e) =>
                                                            setValidity(
                                                                parseFloat(
                                                                    e.target.value
                                                                        .replace(
                                                                            '.',
                                                                            ''
                                                                        )
                                                                        .replace(
                                                                            '.',
                                                                            ''
                                                                        )
                                                                        .replace(
                                                                            ',',
                                                                            '.'
                                                                        )
                                                                )
                                                            )
                                                        }
                                                        value={validity}
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                                'view' ||
                                                            idTypeProjectAmendment.value ===
                                                                1 ||
                                                            idTypeProjectAmendment.value ===
                                                                2 ||
                                                            idTypeProjectAmendment.value ===
                                                                4
                                                                ? true
                                                                : false
                                                        }
                                                        isAllowed={(values) => {
                                                            const {
                                                                floatValue,
                                                                formattedValue,
                                                            } = values;
                                                            return (
                                                                formattedValue ===
                                                                    '' ||
                                                                floatValue <= 72
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <Input
                                                        icon="fas fa-calendar"
                                                        label="Data Prevista da Primeira Visita"
                                                        name="validity"
                                                        type="date"
                                                        placeholder=""
                                                        disabled={true}
                                                        onChange={(e) =>
                                                            setDateFirstVisit(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={dateFirstVisit}
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <Input
                                                        icon="fas fa-calendar"
                                                        label="Data Base"
                                                        min={dateFirstVisit}
                                                        max={maxDate}
                                                        name="validity"
                                                        id="data_base"
                                                        type="date"
                                                        placeholder=""
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            setDateBase(
                                                                e.target.value
                                                            )
                                                        }
                                                        // onChange={ (e) => dateEnd(e) }
                                                        value={dateBase}
                                                        required={true}
                                                    />
                                                    {error ? (
                                                        <p
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {error.dateBase}
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="card-header">
                                        <h3
                                            ref={tecnicoRef}
                                            className="card-title"
                                        >
                                            Técnicos e/ou Estagiários Envolvidos
                                        </h3>
                                    </div>
                                    {alertTecnico ? (
                                        <Alert type="danger">
                                            {alertTecnico}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <label>Coordenador</label>
                                            <Select
                                                // required={true}
                                                value={idCoordinator}
                                                ref={selectCoordinateRef}
                                                label="Coordenador"
                                                options={optionsCoordinator}
                                                onChange={(e) =>
                                                    setIdCoordinator(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!idCoordinator && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={idCoordinator}
                                                    onFocus={() =>
                                                        selectCoordinateRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <hr></hr>
                                    <br></br>
                                    <br></br>
                                    <div className="text-right">
                                        <div
                                            style={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                position: 'absolute',
                                                bottom: '0%',
                                                left: '-2%',
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => history.goBack()}
                                            >
                                                Voltar
                                            </button>

                                            {props.match.params.action ===
                                            'view' ? (
                                                <Link
                                                    style={{
                                                        cursor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 'not-allowed'
                                                                : '',
                                                        color:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#fff'
                                                                : '',
                                                        backgroundColor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#007bff'
                                                                : '',
                                                        borderColor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#007bff'
                                                                : '',
                                                        opacity:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 0.65
                                                                : '',
                                                        boxShadow:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 'none'
                                                                : '',
                                                    }}
                                                    onClick={
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? (e) =>
                                                                  e.preventDefault()
                                                            : ''
                                                    }
                                                    to={`/project_amendment/${props.match.params.id}/edit`}
                                                    className="btn btn-primary"
                                                >
                                                    Editar
                                                </Link>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Salvar
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : idTypeProjectAmendment?.value == 4 ? (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <label>Área</label>
                                            <Select
                                                value={idArea}
                                                ref={selectAreaRef}
                                                label="Área de Produção"
                                                options={optionsArea}
                                                onChange={(e) =>
                                                    handleChangeArea(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view' ||
                                                    !idArea
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Gerente de área</label>
                                            <Select
                                                value={idManager}
                                                ref={selectManagerRef}
                                                label="Single select"
                                                options={managers}
                                                onChange={(e) =>
                                                    setIdManager(e)
                                                }
                                                isDisabled={true}
                                            />
                                            {idManager.length <= 0 && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={idManager}
                                                    onFocus={() =>
                                                        selectManagerRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-key"
                                                label="Centro de custo"
                                                name="centro_custo"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                maxLength={9}
                                                decimalScale={0}
                                                required={false}
                                                placeholder=""
                                                customInput={Input}
                                                value={centroCusto}
                                                onChange={(e) =>
                                                    setCentroCusto(
                                                        e.target.value
                                                    )
                                                }
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-tractor"
                                                label="Tamanho da Propriedade *"
                                                name="acre"
                                                required={
                                                    idTypeProjectAmendment?.value ===
                                                    4
                                                        ? true
                                                        : false
                                                }
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setAcre(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={acre}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 1000000000
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-money-check-alt"
                                                label="Faturamento Anual da Propriedade *"
                                                name="billing property"
                                                allowNegative={false}
                                                required={
                                                    idTypeProjectAmendment?.value ===
                                                    4
                                                        ? true
                                                        : false
                                                }
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setBillingProperty(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={billingProperty}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 9999999
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label>
                                                Atividade Principal do Cliente *
                                            </label>
                                            <Select
                                                required={true}
                                                value={idMainActivity}
                                                ref={selectMainActivityRef}
                                                label="Atividade Principal do Cliente"
                                                options={optionsArea}
                                                onChange={(e) =>
                                                    setIdMainActivity(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {!idMainActivity && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={
                                                        idMainActivity
                                                    }
                                                    onFocus={() =>
                                                        selectMainActivityRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Cliente</label>
                                            <Select
                                                value={id_client}
                                                isMulti
                                                name="clientes"
                                                options={customers}
                                                components={animatedComponents}
                                                closeMenuOnSelect={false}
                                                className="basic-multi-select"
                                                ref={selectClientRef}
                                                classNamePrefix="select"
                                                onChange={handleChangeClient}
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />

                                            {id_client ? (
                                                id_client.length <= 0 ? (
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: 0,
                                                            position:
                                                                'absolute',
                                                        }}
                                                        defaultValue={id_client}
                                                        onFocus={() =>
                                                            selectClientRef.current.focus()
                                                        }
                                                        required={true}
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            ) : id_client == null ? (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={id_client}
                                                    onFocus={() =>
                                                        selectClientRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            ) : (
                                                ''
                                            )}
                                            {error ? (
                                                <p style={{ color: 'red' }}>
                                                    {error.client}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <label>Estado</label>
                                                <Select
                                                    // required={true}
                                                    value={idState}
                                                    ref={selectStateRef}
                                                    // label="Área de Produção"
                                                    isDisabled={true}
                                                    options={optionsState}
                                                    onChange={changeState}
                                                    // isDisabled={true}
                                                />
                                                {/* {!idState && 
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={idState}
												onFocus={() => selectStateRef.current.focus()}
												required={true}
											/>
										}  */}
                                            </div>
                                            <div className="col-md-4">
                                                <label>Cidade</label>
                                                <Select
                                                    // required={true}
                                                    value={idCity}
                                                    isDisabled={true}
                                                    ref={selectCityRef}
                                                    // label="Área de Produção"
                                                    options={optionsCity}
                                                    onChange={(e) =>
                                                        setIdCity(e)
                                                    }
                                                    // isDisabled={true}
                                                />
                                                {/* {!idCity && 
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={idCity}
												onFocus={() => selectCityRef.current.focus()}
												required={true}
											/>
										}  */}
                                            </div>
                                            <div className="col-md-4 mt-1">
                                                <RadioMaterial
                                                    row
                                                    label="Acerto de despesas com o cliente ?"
                                                    aria-label="reembolsavel"
                                                    name="reembolsavel"
                                                    disabled={true}
                                                    value={reembolsavel}
                                                    styleLabel={{
                                                        color: '#BEBEBE',
                                                        fontSize: '10px',
                                                        marginBottom: '15px',
                                                    }}
                                                    onChange={(e) =>
                                                        setReembolsavel(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value="Sim"
                                                        control={<Radio />}
                                                        label="Sim"
                                                    />
                                                    <FormControlLabel
                                                        value="Não"
                                                        control={<Radio />}
                                                        label="Não"
                                                    />
                                                </RadioMaterial>
                                            </div>
                                        </div>
                                    </div>

                                    <hr></hr>
                                    <br></br>
                                    <div className="card-header">
                                        <h3
                                            ref={culturaRef}
                                            className="card-title"
                                        >
                                            Produtividade
                                        </h3>
                                    </div>
                                    {alertCultura ? (
                                        <Alert type="danger">
                                            {alertCultura}
                                        </Alert>
                                    ) : (
                                        ''
                                    )}
                                    <div className="row mt-3">
                                        <div className="col-md-5">
                                            <label>Cultura *</label>
                                            <Select
                                                value={idCulture}
                                                label="typeCharge"
                                                options={optionsCulture}
                                                onChange={(e) =>
                                                    setIdCulture(e)
                                                }
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <NumberFormat
                                                icon="fa-solid fa-asterisk"
                                                label="Quantidade *"
                                                name="valueCulture"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={1}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValueCulture(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valueCulture}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 1000000000
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <div
                                                className="input-group mb-3"
                                                style={{
                                                    paddingTop: '30px',
                                                }}
                                            >
                                                <button
                                                    onClick={handleProdutivity}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    <span className="fas fa-plus"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <TableProdutivity>
                                        <Produtivity
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            produtivity={produtivity}
                                            formatReal={formatNumeric}
                                            handleDeleteProdutivity={
                                                handleDeleteProdutivity
                                            }
                                        />
                                    </TableProdutivity>
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            Dados do Contrato
                                        </h3>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-money-check-alt"
                                                label="Valor Total do Contrato"
                                                name="valueContract"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValueContract(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valueContract}
                                                disabled={true}
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 1000000000
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>
                                                Tipo de Cobrança Parcelas
                                            </label>
                                            <Select
                                                required={true}
                                                value={typeCharge}
                                                ref={selectTypeChargeRef}
                                                label="typeCharge"
                                                options={optionsTypeCharge}
                                                onChange={(e) =>
                                                    setTypeCharge(e)
                                                }
                                                isDisabled={true}
                                            />
                                            {!typeCharge && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={typeCharge}
                                                    onFocus={() =>
                                                        selectTypeChargeRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {typeCharge.value === 'Única' ? (
                                            <>
                                                <div className="col-md-3">
                                                    <Input
                                                        icon="fas fa-money-bill-wave"
                                                        label="Valor da Parcela"
                                                        name="valueParcel"
                                                        type="text"
                                                        // onChange={(e) => setValueContract(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                                                        value={`1x de R$${formatReal(
                                                            valueContract
                                                        )}`}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </>
                                        ) : typeCharge.value === 'Mensal' ? (
                                            <>
                                                <div className="col-md-3">
                                                    <label>
                                                        Valor das Parcelas
                                                    </label>
                                                    <Select
                                                        required={true}
                                                        value={qtdParcel}
                                                        ref={selectQtdParcelRef}
                                                        label="typeCharge"
                                                        options={optionsQtdParcela(
                                                            valueContract
                                                        )}
                                                        onChange={(e) =>
                                                            setQtdParcel(e)
                                                        }
                                                        isDisabled={true}
                                                    />
                                                    {!qtdParcel && (
                                                        <input
                                                            tabIndex={-1}
                                                            autoComplete="off"
                                                            style={{
                                                                opacity: 0,
                                                                width: '100%',
                                                                height: 0,
                                                                position:
                                                                    'absolute',
                                                            }}
                                                            defaultValue={
                                                                qtdParcel
                                                            }
                                                            onFocus={() =>
                                                                selectQtdParcelRef.current.focus()
                                                            }
                                                            required={true}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {typeCharge.value && (
                                            <>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <NumberFormat
                                                        icon="fas fa-money-bill-wave"
                                                        label="Período de Vigência (Meses)"
                                                        name="validity"
                                                        allowNegative={false}
                                                        fixedDecimalScale={true}
                                                        decimalScale={0}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        placeholder=""
                                                        customInput={Input}
                                                        onChange={(e) =>
                                                            setValidity(
                                                                parseFloat(
                                                                    e.target.value
                                                                        .replace(
                                                                            '.',
                                                                            ''
                                                                        )
                                                                        .replace(
                                                                            '.',
                                                                            ''
                                                                        )
                                                                        .replace(
                                                                            ',',
                                                                            '.'
                                                                        )
                                                                )
                                                            )
                                                        }
                                                        value={validity}
                                                        disabled={true}
                                                        isAllowed={(values) => {
                                                            const {
                                                                floatValue,
                                                                formattedValue,
                                                            } = values;
                                                            return (
                                                                formattedValue ===
                                                                    '' ||
                                                                floatValue <= 72
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <Input
                                                        icon="fas fa-calendar"
                                                        label="Data Prevista da Primeira Visita"
                                                        name="validity"
                                                        type="date"
                                                        placeholder=""
                                                        disabled={true}
                                                        onChange={(e) =>
                                                            setDateFirstVisit(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={dateFirstVisit}
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-${
                                                        typeCharge.value ===
                                                        'Conforme Visita'
                                                            ? '4'
                                                            : '3'
                                                    }`}
                                                >
                                                    <Input
                                                        icon="fas fa-calendar"
                                                        label="Data Base"
                                                        min={dateFirstVisit}
                                                        max={maxDate}
                                                        name="validity"
                                                        id="data_base"
                                                        type="date"
                                                        placeholder=""
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            setDateBase(
                                                                e.target.value
                                                            )
                                                        }
                                                        // onChange={ (e) => dateEnd(e) }
                                                        value={dateBase}
                                                        required={true}
                                                    />
                                                    {error ? (
                                                        <p
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {error.dateBase}
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <hr></hr>
                                    <br></br>
                                    <br></br>
                                    <div className="text-right">
                                        <div
                                            style={{
                                                width: '100%',
                                                marginBottom: '20px',
                                                position: 'absolute',
                                                bottom: '0%',
                                                left: '-2%',
                                            }}
                                        >
                                            <button
                                                style={{ marginRight: '10px' }}
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => history.goBack()}
                                            >
                                                Voltar
                                            </button>

                                            {props.match.params.action ===
                                            'view' ? (
                                                <Link
                                                    style={{
                                                        cursor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 'not-allowed'
                                                                : '',
                                                        color:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#fff'
                                                                : '',
                                                        backgroundColor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#007bff'
                                                                : '',
                                                        borderColor:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? '#007bff'
                                                                : '',
                                                        opacity:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 0.65
                                                                : '',
                                                        boxShadow:
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? 'none'
                                                                : '',
                                                    }}
                                                    onClick={
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? (e) =>
                                                                  e.preventDefault()
                                                            : ''
                                                    }
                                                    to={`/project_amendment/${props.match.params.id}/edit`}
                                                    className="btn btn-primary"
                                                >
                                                    Editar
                                                </Link>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Salvar
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="row mt-3">
                                        {/* <div className="col-md-6">
									<label>Tipo do Serviço</label>
									<Select 
										// required={true}
										value={idTypeSalesRecord}
										// label="Área de Produção"
										options={optionsTypeSalesRecord}
										onChange={ (e) => setIdTypeSalesRecord(e) }
										isDisabled={true}
										// isDisabled={true}
									/> 
									
								</div> */}
                                        <div className="col-md-6">
                                            <label>Área</label>
                                            <Select
                                                // required={true}
                                                value={idArea}
                                                ref={selectAreaRef}
                                                label="Área de Produção"
                                                options={optionsArea}
                                                onChange={(e) => setIdArea(e)}
                                                isDisabled={true}
                                            />
                                            {/* {!idArea && 
										<input
											tabIndex={-1}
											autoComplete="off"
											style={{
												opacity: 0,
												width: "100%",
												height: 0,
												position: "absolute"
											}}
											defaultValue={idArea}
											onFocus={() => selectAreaRef.current.focus()}
											required={true}
										/>
									}  */}
                                        </div>
                                        <div className="col-md-6">
                                            <NumberFormat
                                                icon="fas fa-key"
                                                label="Centro de custo"
                                                name="centro_custo"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                maxLength={9}
                                                decimalScale={0}
                                                required={true}
                                                placeholder=""
                                                customInput={Input}
                                                value={centroCusto}
                                                onChange={(e) =>
                                                    setCentroCusto(
                                                        e.target.value
                                                    )
                                                }
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : idTypeProjectAmendment?.value ===
                                                          5
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </div>
                                    {idTypeProjectAmendment?.value === 5 ? (
                                        <div className="row">
                                            <div className="col-md-4">
                                                <NumberFormat
                                                    icon="fas fa-tractor"
                                                    label="Tamanho da Propriedade *"
                                                    name="acre"
                                                    required={
                                                        idTypeProjectAmendment?.value ===
                                                        5
                                                            ? true
                                                            : false
                                                    }
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setAcre(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={acre}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <=
                                                                1000000000
                                                        );
                                                    }}
                                                />
                                                {error ? (
                                                    <p style={{ color: 'red' }}>
                                                        {error.hectaresProperty}
                                                    </p>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                <NumberFormat
                                                    icon="fas fa-money-check-alt"
                                                    label="Faturamento Anual da Propriedade *"
                                                    name="billing property"
                                                    allowNegative={false}
                                                    required={
                                                        idTypeProjectAmendment?.value ===
                                                        5
                                                            ? true
                                                            : false
                                                    }
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setBillingProperty(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={billingProperty}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <=
                                                                9999999
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label>
                                                    Atividade Principal do
                                                    Cliente *
                                                </label>
                                                <Select
                                                    required={
                                                        idTypeProjectAmendment?.value ===
                                                        5
                                                            ? true
                                                            : false
                                                    }
                                                    value={idMainActivity}
                                                    ref={selectMainActivityRef}
                                                    label="Atividade Principal do Cliente"
                                                    options={optionsArea}
                                                    onChange={(e) =>
                                                        setIdMainActivity(e)
                                                    }
                                                    isDisabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {!idMainActivity && (
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: 0,
                                                            position:
                                                                'absolute',
                                                        }}
                                                        defaultValue={
                                                            idMainActivity
                                                        }
                                                        onFocus={() =>
                                                            selectMainActivityRef.current.focus()
                                                        }
                                                        required={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <label>Estado</label>
                                                <Select
                                                    // required={true}
                                                    value={idState}
                                                    ref={selectStateRef}
                                                    // label="Área de Produção"
                                                    isDisabled={true}
                                                    options={optionsState}
                                                    onChange={changeState}
                                                    // isDisabled={true}
                                                />
                                                {/* {!idState && 
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={idState}
												onFocus={() => selectStateRef.current.focus()}
												required={true}
											/>
										}  */}
                                            </div>
                                            <div className="col-md-4">
                                                <label>Cidade</label>
                                                <Select
                                                    // required={true}
                                                    value={idCity}
                                                    isDisabled={true}
                                                    ref={selectCityRef}
                                                    // label="Área de Produção"
                                                    options={optionsCity}
                                                    onChange={(e) =>
                                                        setIdCity(e)
                                                    }
                                                    // isDisabled={true}
                                                />
                                                {/* {!idCity && 
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={idCity}
												onFocus={() => selectCityRef.current.focus()}
												required={true}
											/>
										}  */}
                                            </div>
                                            {idTypeProjectAmendment?.value ===
                                            5 ? (
                                                <div className="col-md-4 mt-1">
                                                    <RadioMaterial
                                                        row
                                                        label="Acerto de despesas com o cliente ?"
                                                        aria-label="reembolsavel"
                                                        name="reembolsavel"
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                        }
                                                        value={reembolsavel}
                                                        styleLabel={{
                                                            color: '#BEBEBE',
                                                            fontSize: '10px',
                                                            marginBottom:
                                                                '15px',
                                                        }}
                                                        onChange={(e) =>
                                                            setReembolsavel(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            value="Sim"
                                                            control={<Radio />}
                                                            label="Sim"
                                                        />
                                                        <FormControlLabel
                                                            value="Não"
                                                            control={<Radio />}
                                                            label="Não"
                                                        />
                                                    </RadioMaterial>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-6">
										<label>Coordenador</label>
										<Select 
											required={true}
											value={idCoordinator}
											ref={selectCoordinateRef}
											label="Coordenador"
											options={optionsCoordinator}
											onChange={ (e) => setIdCoordinator(e) }
											isDisabled={props.match.params.action === 'view' ? true : false}
										/> 
										{!idCoordinator && 
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={idCoordinator}
												onFocus={() => selectCoordinateRef.current.focus()}
												required={true}
											/>
										} 
									</div> */}
                                        </div>
                                        {idTypeProjectAmendment?.value === 5 ? (
                                            <div className="row">
                                                {/* <div className="col-md-6">
										<TextArea
											rows="6"
											label="Descrição do Serviço / Informações do Cliente"
											value={description}
											maxLength="255"
											onChange={(e) => setDescription(e.target.value)}
											disabled={props.match.params.action === 'view' ? true : false}
										/>
									</div> */}

                                                <div className="col-md-12">
                                                    <label>
                                                        Anexo da Proposta
                                                    </label>
                                                    <ContentUpload>
                                                        <>
                                                            <Upload
                                                                required={
                                                                    parseInt(
                                                                        typeCharge.value
                                                                    ) === 1 ||
                                                                    parseInt(
                                                                        typeCharge.value
                                                                    ) === 3
                                                                        ? true
                                                                        : false ||
                                                                          idTypeProjectAmendment?.value ===
                                                                              5
                                                                        ? true
                                                                        : false
                                                                }
                                                                disabled={
                                                                    props.match
                                                                        .params
                                                                        .action ===
                                                                    'view'
                                                                        ? true
                                                                        : false
                                                                }
                                                                onUpload={
                                                                    handleUpload
                                                                }
                                                            />
                                                            {!!uploadedFiles.length && (
                                                                <FileList
                                                                    action={
                                                                        props
                                                                            .match
                                                                            .params
                                                                            .action
                                                                    }
                                                                    toEdit={
                                                                        props
                                                                            .match
                                                                            .params
                                                                            .action ===
                                                                        'edit'
                                                                            ? `/project_amendment/${props.match.params.id}/edit`
                                                                            : ''
                                                                    }
                                                                    toCadastro="new_project_amendment"
                                                                    files={
                                                                        uploadedFiles
                                                                    }
                                                                    onDelete={
                                                                        handleDeleteAttachment
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    </ContentUpload>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {idTypeProjectAmendment?.value === 5 ? (
                                            <hr></hr>
                                        ) : (
                                            ''
                                        )}
                                        <br></br>
                                        {idTypeProjectAmendment?.value === 5 ? (
                                            <>
                                                <div className="card-header">
                                                    <h3
                                                        ref={culturaRef}
                                                        className="card-title"
                                                    >
                                                        Produtividade
                                                    </h3>
                                                </div>
                                                {alertCultura ? (
                                                    <Alert type="danger">
                                                        {alertCultura}
                                                    </Alert>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="row mt-3">
                                                    <div className="col-md-5">
                                                        <label>Cultura *</label>
                                                        <Select
                                                            value={idCulture}
                                                            label="typeCharge"
                                                            options={
                                                                optionsCulture
                                                            }
                                                            onChange={(e) =>
                                                                setIdCulture(e)
                                                            }
                                                            isDisabled={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                'view'
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-5">
                                                        <NumberFormat
                                                            icon="fa-solid fa-asterisk"
                                                            label="Quantidade *"
                                                            name="valueCulture"
                                                            allowNegative={
                                                                false
                                                            }
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            decimalScale={1}
                                                            thousandSeparator={
                                                                '.'
                                                            }
                                                            decimalSeparator={
                                                                ','
                                                            }
                                                            placeholder=""
                                                            customInput={Input}
                                                            onChange={(e) =>
                                                                setValueCulture(
                                                                    parseFloat(
                                                                        e.target.value
                                                                            .replace(
                                                                                '.',
                                                                                ''
                                                                            )
                                                                            .replace(
                                                                                '.',
                                                                                ''
                                                                            )
                                                                            .replace(
                                                                                ',',
                                                                                '.'
                                                                            )
                                                                    )
                                                                )
                                                            }
                                                            value={valueCulture}
                                                            disabled={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                'view'
                                                                    ? true
                                                                    : false
                                                            }
                                                            isAllowed={(
                                                                values
                                                            ) => {
                                                                const {
                                                                    floatValue,
                                                                    formattedValue,
                                                                } = values;
                                                                return (
                                                                    formattedValue ===
                                                                        '' ||
                                                                    floatValue <=
                                                                        1000000000
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div
                                                            className="input-group mb-3"
                                                            style={{
                                                                paddingTop:
                                                                    '30px',
                                                            }}
                                                        >
                                                            <button
                                                                onClick={
                                                                    handleProdutivity
                                                                }
                                                                disabled={
                                                                    props.match
                                                                        .params
                                                                        .action ===
                                                                    'view'
                                                                        ? true
                                                                        : false
                                                                }
                                                                type="button"
                                                                className="btn btn-primary"
                                                            >
                                                                <span className="fas fa-plus"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <TableProdutivity>
                                                    <Produtivity
                                                        isDisabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                                ? true
                                                                : false
                                                        }
                                                        produtivity={
                                                            produtivity
                                                        }
                                                        formatReal={
                                                            formatNumeric
                                                        }
                                                        handleDeleteProdutivity={
                                                            handleDeleteProdutivity
                                                        }
                                                    />
                                                </TableProdutivity>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <hr></hr>
                                        <br></br>
                                        {idTypeProjectAmendment?.value === 5 ? (
                                            <>
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        Informações da Venda
                                                    </h3>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-4">
                                                        <Input
                                                            icon="fas fa-calendar"
                                                            label="Data da Venda"
                                                            name="dateSale"
                                                            type="date"
                                                            placeholder=""
                                                            disabled={true}
                                                            onChange={(e) =>
                                                                setDateSale(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={dateSale}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Agente da Venda
                                                        </label>
                                                        <Select
                                                            required={true}
                                                            value={agentSale}
                                                            ref={
                                                                selectAgentSaleRef
                                                            }
                                                            options={
                                                                optionsAgentSale
                                                            }
                                                            onChange={(e) =>
                                                                setAgentSale(e)
                                                            }
                                                            isDisabled={true}
                                                        />
                                                        {/* {!agentSale &&
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={agentSale}
												onFocus={() => selectAgentSaleRef.current.focus()}
												required={true}
											/>
										}  */}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Origem da Venda
                                                        </label>
                                                        <Select
                                                            required={true}
                                                            value={originOfSale}
                                                            ref={
                                                                selectOriginOfSaleRef
                                                            }
                                                            label="typeCharge"
                                                            options={
                                                                optionsOriginOfSale
                                                            }
                                                            onChange={(e) =>
                                                                setOriginOfSale(
                                                                    e
                                                                )
                                                            }
                                                            isDisabled={true}
                                                        />
                                                        {/* {!originOfSale &&
											<input
												tabIndex={-1}
												autoComplete="off"
												style={{
													opacity: 0,
													width: "100%",
													height: 0,
													position: "absolute"
												}}
												defaultValue={originOfSale}
												onFocus={() => selectOriginOfSaleRef.current.focus()}
												required={true}
											/>
										}  */}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <br></br>
                                        <div className="card-header">
                                            <h3
                                                ref={produtoRef}
                                                className="card-title"
                                            >
                                                Produtos
                                            </h3>
                                        </div>
                                        {alertProduto ? (
                                            <Alert type="danger">
                                                {alertProduto}
                                            </Alert>
                                        ) : (
                                            ''
                                        )}
                                        <div className="row mt-3">
                                            <div className="col-md-5">
                                                <label>Produto *</label>
                                                <Select
                                                    ref={selectProdutoRef}
                                                    value={idProduct}
                                                    label="typeCharge"
                                                    options={optionsProduct}
                                                    onChange={(e) =>
                                                        setIdProduct(e)
                                                    }
                                                    isDisabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <NumberFormat
                                                    icon="fas fa-money-check-alt"
                                                    label="Valor *"
                                                    name="valueProduct"
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setValueProduct(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={valueProduct}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <=
                                                                1000000000
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <div
                                                    className="input-group mb-3"
                                                    style={{
                                                        paddingTop: '30px',
                                                    }}
                                                >
                                                    <button
                                                        onClick={handleProduct}
                                                        disabled={
                                                            props.match.params
                                                                .action ===
                                                            'view'
                                                                ? true
                                                                : false
                                                        }
                                                        type="button"
                                                        className="btn btn-primary"
                                                    >
                                                        <span className="fas fa-plus"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <TableProducts>
                                                <Products
                                                    isDisabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    product={product}
                                                    formatReal={formatReal}
                                                    handleDeleteProduct={
                                                        handleDeleteProduct
                                                    }
                                                />
                                            </TableProducts>
                                        </div>
                                        <br></br>
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Dados do Contrato
                                            </h3>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <NumberFormat
                                                    icon="fas fa-money-check-alt"
                                                    label="Valor Total do Contrato"
                                                    name="valueContract"
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setValueContract(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={valueContract}
                                                    disabled={true}
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <=
                                                                1000000000
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>
                                                    Tipo de Cobrança Parcelas
                                                </label>
                                                <Select
                                                    required={true}
                                                    value={typeCharge}
                                                    ref={selectTypeChargeRef}
                                                    label="typeCharge"
                                                    options={optionsTypeCharge}
                                                    onChange={(e) =>
                                                        setTypeCharge(e)
                                                    }
                                                    isDisabled={
                                                        props.match.params
                                                            .action ===
                                                            'view' ||
                                                        valueContract <= 0 ||
                                                        valueContract === ''
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {!typeCharge && (
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: 0,
                                                            position:
                                                                'absolute',
                                                        }}
                                                        defaultValue={
                                                            typeCharge
                                                        }
                                                        onFocus={() =>
                                                            selectTypeChargeRef.current.focus()
                                                        }
                                                        required={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {typeCharge.value === 'Variável' ? (
                                            <div className="col-md-12">
                                                <TextArea
                                                    rows="6"
                                                    label="Descrição dos Valores de Parcelas"
                                                    value={descriptionValue}
                                                    maxLength="1000"
                                                    onChange={(e) =>
                                                        setDescriptionValue(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="row">
                                            {typeCharge.value === 'Única' ? (
                                                <>
                                                    <div className="col-md-3">
                                                        <Input
                                                            icon="fas fa-money-bill-wave"
                                                            label="Valor da Parcela"
                                                            name="valueParcel"
                                                            type="text"
                                                            // onChange={(e) => setValueContract(parseFloat(e.target.value.replace('.','').replace('.','').replace(',','.')))}
                                                            value={`1x de R$${formatReal(
                                                                valueContract
                                                            )}`}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </>
                                            ) : typeCharge.value ===
                                              'Mensal' ? (
                                                <>
                                                    <div className="col-md-3">
                                                        <label>
                                                            Valor das Parcelas
                                                        </label>
                                                        <Select
                                                            required={true}
                                                            value={qtdParcel}
                                                            ref={
                                                                selectQtdParcelRef
                                                            }
                                                            label="typeCharge"
                                                            options={optionsQtdParcela(
                                                                valueContract
                                                            )}
                                                            onChange={(e) =>
                                                                setQtdParcel(e)
                                                            }
                                                            isDisabled={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                    'view' ||
                                                                idTypeProjectAmendment.value ===
                                                                    1 ||
                                                                idTypeProjectAmendment.value ===
                                                                    2 ||
                                                                idTypeProjectAmendment.value ===
                                                                    4
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {!qtdParcel && (
                                                            <input
                                                                tabIndex={-1}
                                                                autoComplete="off"
                                                                style={{
                                                                    opacity: 0,
                                                                    width: '100%',
                                                                    height: 0,
                                                                    position:
                                                                        'absolute',
                                                                }}
                                                                defaultValue={
                                                                    qtdParcel
                                                                }
                                                                onFocus={() =>
                                                                    selectQtdParcelRef.current.focus()
                                                                }
                                                                required={true}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {typeCharge.value && (
                                                <>
                                                    <div
                                                        className={`col-md-${
                                                            typeCharge.value ===
                                                            'Conforme Visita'
                                                                ? '4'
                                                                : '3'
                                                        }`}
                                                    >
                                                        <NumberFormat
                                                            icon="fas fa-money-bill-wave"
                                                            label="Período de Vigência (Meses)"
                                                            name="validity"
                                                            allowNegative={
                                                                false
                                                            }
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            decimalScale={0}
                                                            thousandSeparator={
                                                                '.'
                                                            }
                                                            decimalSeparator={
                                                                ','
                                                            }
                                                            placeholder=""
                                                            customInput={Input}
                                                            onChange={(e) =>
                                                                setValidity(
                                                                    parseFloat(
                                                                        e.target.value
                                                                            .replace(
                                                                                '.',
                                                                                ''
                                                                            )
                                                                            .replace(
                                                                                '.',
                                                                                ''
                                                                            )
                                                                            .replace(
                                                                                ',',
                                                                                '.'
                                                                            )
                                                                    )
                                                                )
                                                            }
                                                            value={validity}
                                                            disabled={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                    'view' ||
                                                                idTypeProjectAmendment.value ===
                                                                    1 ||
                                                                idTypeProjectAmendment.value ===
                                                                    2 ||
                                                                idTypeProjectAmendment.value ===
                                                                    4
                                                                    ? true
                                                                    : false
                                                            }
                                                            isAllowed={(
                                                                values
                                                            ) => {
                                                                const {
                                                                    floatValue,
                                                                    formattedValue,
                                                                } = values;
                                                                return (
                                                                    formattedValue ===
                                                                        '' ||
                                                                    floatValue <=
                                                                        72
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-md-${
                                                            typeCharge.value ===
                                                            'Conforme Visita'
                                                                ? '4'
                                                                : '3'
                                                        }`}
                                                    >
                                                        <Input
                                                            icon="fas fa-calendar"
                                                            label="Data Prevista da Primeira Visita"
                                                            name="validity"
                                                            type="date"
                                                            placeholder=""
                                                            disabled={true}
                                                            onChange={(e) =>
                                                                setDateFirstVisit(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={
                                                                dateFirstVisit
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`col-md-${
                                                            typeCharge.value ===
                                                            'Conforme Visita'
                                                                ? '4'
                                                                : '3'
                                                        }`}
                                                    >
                                                        <Input
                                                            icon="fas fa-calendar"
                                                            label="Data Base"
                                                            min={dateFirstVisit}
                                                            max={maxDate}
                                                            name="validity"
                                                            id="data_base"
                                                            type="date"
                                                            placeholder=""
                                                            disabled={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                'view'
                                                                    ? true
                                                                    : false
                                                            }
                                                            // onChange={(e) => setDateBase(e.target.value)}
                                                            onChange={(e) =>
                                                                setDateBase(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            // onChange={(e) =>
                                                            //     dateEnd(e)
                                                            // }
                                                            value={dateBase}
                                                            required={true}
                                                        />
                                                        {error ? (
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {error.dateBase}
                                                            </p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextArea
                                                            rows="6"
                                                            label="Descrição do Serviço / Informações do Cliente"
                                                            value={description}
                                                            maxLength="254"
                                                            onChange={(e) =>
                                                                setDescription(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            disabled={
                                                                props.match
                                                                    .params
                                                                    .action ===
                                                                'view'
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {error ? (
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {
                                                                    error.description
                                                                }
                                                            </p>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <hr></hr>
                                        <br></br>
                                        <div className="card-header">
                                            <h3
                                                ref={margemRef}
                                                className="card-title"
                                            >
                                                Margens de Apuração *
                                            </h3>
                                        </div>
                                        {alertMargem ? (
                                            <Alert type="danger">
                                                {alertMargem}
                                            </Alert>
                                        ) : (
                                            ''
                                        )}
                                        <div className="row mt-3">
                                            <div className={'col-md-3'}>
                                                <NumberFormat
                                                    icon="fas fa-money-bill-wave"
                                                    label="Impostos *"
                                                    name="validity"
                                                    required={
                                                        idTypeProjectAmendment?.value ===
                                                        3
                                                            ? false
                                                            : idTypeProjectAmendment?.value ===
                                                              5
                                                            ? false
                                                            : true
                                                    }
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setImposto(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={imposto}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              3
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              5
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <= 100
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-3'}>
                                                <NumberFormat
                                                    icon="fas fa-money-bill-wave"
                                                    label="Margem Administrativa *"
                                                    name="validity"
                                                    required={
                                                        idTypeProjectAmendment?.value ===
                                                        3
                                                            ? false
                                                            : idTypeProjectAmendment?.value ===
                                                              5
                                                            ? false
                                                            : true
                                                    }
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setMargemAdm(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={margemAdm}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              3
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              5
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <= 100
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-3'}>
                                                <NumberFormat
                                                    icon="fas fa-money-bill-wave"
                                                    label="Margem Líquida *"
                                                    name="validity"
                                                    required={
                                                        idTypeProjectAmendment?.value ===
                                                        3
                                                            ? false
                                                            : idTypeProjectAmendment?.value ===
                                                              5
                                                            ? false
                                                            : true
                                                    }
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setMargemLqd(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={margemLqd}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              3
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              5
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <= 100
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-3'}>
                                                <NumberFormat
                                                    icon="fas fa-money-bill-wave"
                                                    label="Margem Adicional *"
                                                    name="validity"
                                                    required={true}
                                                    allowNegative={false}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={'.'}
                                                    decimalSeparator={','}
                                                    placeholder=""
                                                    customInput={Input}
                                                    onChange={(e) =>
                                                        setMargemAd(
                                                            parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        '.',
                                                                        ''
                                                                    )
                                                                    .replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                            )
                                                        )
                                                    }
                                                    value={margemAd}
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    isAllowed={(values) => {
                                                        const {
                                                            floatValue,
                                                            formattedValue,
                                                        } = values;
                                                        return (
                                                            formattedValue ===
                                                                '' ||
                                                            floatValue <= 100
                                                        );
                                                    }}
                                                />
                                                <TextArea
                                                    rows="6"
                                                    required={false}
                                                    label="Descrição"
                                                    value={
                                                        descriptionAdditionalMargin
                                                    }
                                                    maxLength="255"
                                                    onChange={(e) =>
                                                        setDescriptionAdditionalMargin(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <br></br>
                                        <div className="card-header">
                                            <h3
                                                ref={tecnicoRef}
                                                className="card-title"
                                            >
                                                Técnicos e/ou Estagiários
                                                Envolvidos
                                            </h3>
                                        </div>
                                        {alertTecnico ? (
                                            <Alert type="danger">
                                                {alertTecnico}
                                            </Alert>
                                        ) : (
                                            ''
                                        )}
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <label>Coordenador</label>
                                                <Select
                                                    // required={true}
                                                    value={idCoordinator}
                                                    ref={selectCoordinateRef}
                                                    label="Coordenador"
                                                    options={optionsCoordinator}
                                                    onChange={(e) =>
                                                        setIdCoordinator(e)
                                                    }
                                                    isDisabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : idTypeProjectAmendment?.value ===
                                                              3
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {!idCoordinator && (
                                                    <input
                                                        tabIndex={-1}
                                                        autoComplete="off"
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: 0,
                                                            position:
                                                                'absolute',
                                                        }}
                                                        defaultValue={
                                                            idCoordinator
                                                        }
                                                        onFocus={() =>
                                                            selectCoordinateRef.current.focus()
                                                        }
                                                        required={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {errorTechnical && (
                                            <Alert type="danger">
                                                {errorTechnical}
                                            </Alert>
                                        )}

                                        <br></br>
                                        <div className="text-right">
                                            <div
                                                style={{
                                                    width: '100%',
                                                    marginBottom: '20px',
                                                    position: 'absolute',
                                                    bottom: '0%',
                                                    left: '-2%',
                                                }}
                                            >
                                                <button
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={() =>
                                                        history.goBack()
                                                    }
                                                >
                                                    Voltar
                                                </button>

                                                {props.match.params.action ===
                                                'view' ? (
                                                    <Link
                                                        style={{
                                                            cursor:
                                                                created_by !==
                                                                    id_user ||
                                                                id_status !== 1
                                                                    ? 'not-allowed'
                                                                    : '',
                                                            color:
                                                                created_by !==
                                                                    id_user ||
                                                                id_status !== 1
                                                                    ? '#fff'
                                                                    : '',
                                                            backgroundColor:
                                                                created_by !==
                                                                    id_user ||
                                                                id_status !== 1
                                                                    ? '#007bff'
                                                                    : '',
                                                            borderColor:
                                                                created_by !==
                                                                    id_user ||
                                                                id_status !== 1
                                                                    ? '#007bff'
                                                                    : '',
                                                            opacity:
                                                                created_by !==
                                                                    id_user ||
                                                                id_status !== 1
                                                                    ? 0.65
                                                                    : '',
                                                            boxShadow:
                                                                created_by !==
                                                                    id_user ||
                                                                id_status !== 1
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                        onClick={
                                                            created_by !==
                                                                id_user ||
                                                            id_status !== 1
                                                                ? (e) =>
                                                                      e.preventDefault()
                                                                : ''
                                                        }
                                                        to={`/project_amendment/${props.match.params.id}/edit`}
                                                        className="btn btn-primary"
                                                    >
                                                        Editar
                                                    </Link>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Salvar
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                    </Form>
                    {idProject &&
                        idTypeProjectAmendment?.value != 1 &&
                        idTypeProjectAmendment?.value != 4 && (
                            <>
                                <Form onSubmit={handleTechnical}>
                                    <div className="row mt-4">
                                        <div className="col-md-2">
                                            <label>Técnico</label>
                                            <Select
                                                value={idTechnical}
                                                onChange={(e) =>
                                                    setIdTechnical(e)
                                                }
                                                options={optionsTechnical}
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                label="Single select"
                                                ref={selectTechnicalRef}
                                            />
                                            {idTechnical.length <= 0 && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={idTechnical}
                                                    onFocus={() =>
                                                        selectTechnicalRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-2">
                                            <label>Tipo de dedicação</label>
                                            <Select
                                                label="Single select"
                                                value={idTypeDedication}
                                                options={optionsTypeDedication}
                                                onChange={(e) =>
                                                    setIdTypeDedication(e)
                                                }
                                                ref={selectTypeDedicationRef}
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {idTypeDedication.length <= 0 && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={
                                                        idTypeDedication
                                                    }
                                                    onFocus={() =>
                                                        selectTypeDedicationRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-2">
                                            <Input
                                                icon="fas fa-calendar"
                                                label="Data de Alocação"
                                                min={today}
                                                name="validity"
                                                type="date"
                                                placeholder=""
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    setDateAlocacao(
                                                        e.target.value
                                                    )
                                                }
                                                value={dateAlocacao}
                                                required={true}
                                            />
                                            {error ? (
                                                <p
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    {error.dateAlocacao}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="col-md-1">
                                            <NumberFormat
                                                icon="fas fa-user-tie"
                                                label="Dedicação (Dias)"
                                                name="dedicacao"
                                                decimalScale={0}
                                                placeholder=""
                                                customInput={Input}
                                                allowNegative={false}
                                                onChange={(e) =>
                                                    e.target.value
                                                        ? setDedication(
                                                              parseInt(
                                                                  e.target.value
                                                              )
                                                          )
                                                        : setDedication(
                                                              e.target.value
                                                          )
                                                }
                                                value={dedication}
                                                isAllowed={(values) => {
                                                    const {
                                                        floatValue,
                                                        formattedValue,
                                                    } = values;
                                                    return (
                                                        formattedValue === '' ||
                                                        floatValue <= 99999
                                                    );
                                                }}
                                                required={
                                                    parseInt(
                                                        idRemuneration.value
                                                    ) === 2
                                                        ? true
                                                        : false
                                                }
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {/* {props.errorValueTechnical} */}
                                        </div>
                                        <div className="col-md-2">
                                            <label>Remuneração RMA*</label>
                                            <Select
                                                label="Single select"
                                                value={idRemuneration}
                                                options={
                                                    typeProject === 1 &&
                                                    (tipoServico == 1 ||
                                                        tipoServico == 2) &&
                                                    (idTypeProjectAmendment?.value ===
                                                        2 ||
                                                        idTypeProjectAmendment?.value ===
                                                            3 ||
                                                        idTypeProjectAmendment?.value ===
                                                            5)
                                                        ? optionsRemunerationNotMonthly
                                                        : optionsRemuneration
                                                }
                                                onChange={(e) =>
                                                    setIdRemuneration(e)
                                                }
                                                ref={selectRemunerationRef}
                                                isDisabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {idRemuneration.length <= 0 && (
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: '100%',
                                                        height: 0,
                                                        position: 'absolute',
                                                    }}
                                                    defaultValue={
                                                        idRemuneration
                                                    }
                                                    onFocus={() =>
                                                        selectRemunerationRef.current.focus()
                                                    }
                                                    required={true}
                                                />
                                            )}
                                        </div>
                                        <div className="col-md-1">
                                            <NumberFormat
                                                icon="fas fa-dollar-sign"
                                                label="Valor RMA"
                                                name="valor"
                                                allowNegative={false}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                placeholder=""
                                                customInput={Input}
                                                onChange={(e) =>
                                                    setValueTechnical(
                                                        parseFloat(
                                                            e.target.value
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    '.',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    )
                                                }
                                                value={valueTechnical}
                                                required={true}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {/* {props.errorValueTechnical} */}
                                        </div>
                                        <div className="col-md-1">
                                            <div
                                                className="input-group mb-3"
                                                style={{ paddingTop: '30px' }}
                                            >
                                                <button
                                                    disabled={
                                                        props.match.params
                                                            .action === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    <span className="fas fa-plus"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                <div style={{ marginBottom: '100px' }}>
                                    <TableTechnicalProjectAmendment>
                                        <Technical
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            technical={technical}
                                            formatReal={formatReal}
                                            handleDeleteTechnical={
                                                handleDeleteTechnical
                                            }
                                        />
                                    </TableTechnicalProjectAmendment>
                                </div>
                            </>
                        )}
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

export default ProjectAmendment;
