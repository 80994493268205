/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Form from '../../../Forms/Form';
import Input from '../../../Forms/Input';
import Select from 'react-select';
import StylePage from '../../../Style Page/StylePage';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Footer from '../../../Footer/Footer';
import NumberFormat from 'react-number-format';
import { uniqueId } from 'lodash';
// import filesize from 'filesize'
import TextArea from '../../../Forms/TextArea';
import { useSelector } from 'react-redux';
import {
    getResource,
    getViewExtraCharge,
    postExtraCharge,
    putExtraCharge,
} from '../../../Services/extraCharge';
import TableTechnicalExtraCharge from './TableTechnicalExtraCharge';
import Technical from './Technical';
import { useHistory, Link } from 'react-router-dom';
import Alert from '../../../Alerts/Alert';
import { Content } from '../../../LoadingStyle/StyleLoading';
import ReactLoading from 'react-loading';
import { get_view_project } from '../../../Services/project';
import TableProducts from './TableProducts';
import Products from './Products';

function ExtraCharge(props) {
    function formatReal(desc) {
        var tmp = `${desc.toFixed(2)}`.replace('.', ',');

        if (tmp.length > 6)
            tmp = tmp.replace(/(([0-9]{3}),([0-9]{2}$)*)/g, '.$1');
        return tmp;
    }
    let history = useHistory();
    const selectTechnicalRef = useRef(null);
    const selectProductRef = useRef(null);
    const selectProjectRef = useRef(null);
    const margemRef = useRef(null);
    const [created_by, setCreated] = useState('');
    const [id_status, setIdStatus] = useState('');
    const [dedication, setDedication] = useState('');
    const [product, setProduct] = useState([]);
    const id_user = useSelector((state) => state.auth.user.id);
    const [loading, setLoading] = useState(false);
    // const [ error, setError ] = useState([])
    const [errorTechnical, setErrorTechnical] = useState('');
    const [success, setSuccess] = useState('');
    const [alert, setAlert] = useState('');
    const [alertTecnico, setAlertTecnico] = useState('');
    const [alertMargem, setAlertMargem] = useState('');
    const [idProject, setIdProject] = useState('');
    const [description, setDescription] = useState('');
    // const [ uploadedFiles, setUploadedFiles ] = useState([])
    const [value, setValue] = useState(0);
    const [dateFirst, setDateFirst] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [dateFirstProject, setDateFirstProject] = useState('');
    const [dateEndProject, setDateEndProject] = useState('');
    const [optionsTechnical, setOptionsTechnical] = useState([]);
    const [optionsProject, setoptionsProject] = useState([]);
    const [optionsProduct, setOptionsProduct] = useState([]);
    const [idProduct, setIdProduct] = useState('');
    const produtoRef = useRef(null);
    const technicalRef = useRef(null);
    const [alertProduto, setAlertProduto] = useState('');
    const [valueProduct, setValueProduct] = useState('');
    const [technical, setTechnical] = useState([]);
    const [idTechnical, setIdTechnical] = useState([]);
    const [idRemuneration, setIdRemuneration] = useState([]);
    const [valueTechnical, setValueTechnical] = useState('');
    const [imposto, setImposto] = useState(16);
    const [margemAdm, setMargemAdm] = useState(10);
    const [margemLqd, setMargemLqd] = useState(20);
    const [margemAd, setMargemAd] = useState(0);
    const [codigo, setCodigo] = useState('');

    const perfis = useSelector((state) => state.auth.user.perfis) || [];

    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        viewPerId();
    }, []);

    useEffect(() => {
        dateProject();
    }, [idProject]);

    function dateProject() {
        idProject &&
            get_view_project(idProject.value).then((resp) => {
                setDateFirstProject(resp.data.project[0].data_inicio);
                setDateEndProject(resp.data.project[0].data_fim);
            });
    }

    function isEdit(created, status) {
        if (props.match.params.action === 'edit') {
            if (perfis[0] === 1 || perfis[0] === 6) {
                return;
            } else if (
                parseInt(created) !== parseInt(id_user) ||
                parseInt(status) !== 1
            ) {
                props.history.push(
                    `/extra_charge/${props.match.params.id}/view`
                );
            }
        }
    }

    function getDataAll() {
        getResource().then((resp) => {
            setoptionsProject(
                resp.data.project.map((desc) => ({
                    value: desc.id,
                    label: desc.projeto,
                }))
            );

            setOptionsTechnical(
                resp.data.technical.map((desc) => ({
                    value: desc.id,
                    label: desc.name,
                }))
            );
            setOptionsProduct(
                resp.data.product.map((desc) => ({
                    value: desc.id,
                    label: desc.descricao,
                }))
            );
            // setOptionsRemuneration(resp.data.remuneration.map(desc => ({
            // 	value: desc.id,
            // 	label: desc.descricao
            // })))
        });
    }

    function viewPerId() {
        props.match.params.action &&
            getViewExtraCharge(props.match.params.id).then((resp) => {
                setIdProject({
                    value: resp.data.extraCharge[0].idProjeto,
                    label: resp.data.extraCharge[0].projeto,
                });
                setDescription(resp.data.extraCharge[0].descricao);
                setValue(resp.data.extraCharge[0].valor);
                setDateFirst(resp.data.extraCharge[0].data_inicio);
                setDateEnd(resp.data.extraCharge[0].data_fim);
                setCreated(resp.data.extraCharge[0].created_by);
                setIdStatus(resp.data.extraCharge[0].id_status);
                setImposto(resp.data.extraCharge[0].imposto);
                setMargemAdm(resp.data.extraCharge[0].margemAdm);
                setMargemLqd(resp.data.extraCharge[0].margemLqd);
                setCodigo(resp.data.extraCharge[0].codigo);
                setMargemAd(resp.data.extraCharge[0].margemAd);
                setProduct(
                    resp.data.product.map((desc) => ({
                        id: uniqueId(),
                        idProduct: desc.id_produto,
                        nameProduct: desc.produto,
                        value: desc.valor,
                    }))
                );
                setTechnical(
                    resp.data.technical.map((desc) => ({
                        id: uniqueId(),
                        idTechnical: desc.id_user,
                        nameTechnical: desc.user_name,
                        idRemuneration: desc.id_tipo_remuneracao,
                        nameRemuneration: desc.remuneracao,
                        value: desc.valor,
                        dedication: desc.dedicacao,
                    }))
                );
                isEdit(
                    resp.data.extraCharge[0].created_by,
                    resp.data.extraCharge[0].id_status
                );
            });
    }

    // const handleUpload = files => {
    //     const uploadedFile = files.map(file=>({
    //         file,
    //         id:uniqueId(),
    //         name:file.name,
    //         readableSize:filesize(file.size),

    //         progress:0,
    //         uploaded:false,
    //         error:false,
    //         url:null

    //     }))
    //     setUploadedFiles(uploadedFiles.concat(uploadedFile))
    // }

    // const handleDelete = id => {
    //     const upload = uploadedFiles.filter(file =>file.id === id)
    //     if(upload[0].url){
    //         delAttachment(id)
    //         .then(resp=> (
    //             viewPerId(props.match.params.id)
    //         ));
    //     }else{
    //         setUploadedFiles(uploadedFiles.filter(file =>file.id !== id))
    //     }
    // }

    const handleTechnical = (e) => {
        e.preventDefault();
        let validate =
            technical.filter(
                (desc) =>
                    parseInt(desc.idTechnical) === parseInt(idTechnical.value)
            ) || [];
        if (validate.length > 0) {
            return [
                setErrorTechnical(
                    'Não é possivel adicionar técnicos repetidos'
                ),
                setTimeout(() => {
                    setErrorTechnical('');
                }, 3000),
            ];
        }

        const data = {
            id: uniqueId(),
            idTechnical: idTechnical.value,
            nameTechnical: idTechnical.label,
            idRemuneration: idRemuneration.value,
            nameRemuneration: idRemuneration.label,
            dedication: dedication,
            value: valueTechnical,
        };

        setTechnical(technical.concat(data));
        setIdTechnical([]);
        setIdRemuneration([]);
        setValueTechnical('');
        setDedication('');
        setAlertTecnico('');
    };

    const handleDeleteTechnical = (id) => {
        setTechnical(technical.filter((desc) => desc.id !== id));
    };

    const handleDeleteProduct = (id) => {
        let valueProduct = product.filter(
            (desc) => parseInt(desc.id) === parseInt(id)
        )[0].value;
        setValue(value - valueProduct);
        setProduct(product.filter((desc) => desc.id !== id));
    };

    const handleProduct = (e) => {
        e.preventDefault();
        var errorCheck = false;
        console.log(!valueProduct);

        if (!idProduct || (!valueProduct && valueProduct !== 0)) {
            return setAlertProduto(
                'É necessário escolher um produto e preencher o valor para adicionar um novo produto !'
            );
        }

        let validate =
            product.filter(
                (desc) => parseInt(desc.idProduct) === parseInt(idProduct.value)
            ) || [];
        if (validate.length > 0) {
            return setAlertProduto('Não é possivel adicionar um mesmo produto');
        }

        setAlertProduto('');

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = {
            id: uniqueId(),
            idProduct: idProduct.value,
            nameProduct: idProduct.label,
            value: valueProduct,
        };

        setValue(value + valueProduct);
        setProduct(product.concat(data));
        setIdProduct('');
        setValueProduct('');
    };

    const handleForm = (e) => {
        e.preventDefault();

        var errorCheck = false;
        setLoading(true);

        if (product.length <= 0) {
            setAlertProduto(
                'Você precisa adicionar produtos no registro de venda !'
            );
            errorCheck = true;
            produtoRef.current.scrollIntoView();
        } else {
            setAlertProduto('');
        }

        if (technical.length <= 0) {
            setAlertTecnico(
                'Você precisa adicionar técnicos no registro de serviço extra !'
            );
            errorCheck = true;
            technicalRef.current.scrollIntoView();
        } else {
            setAlertTecnico('');
        }

        if (imposto + margemAdm + margemLqd + margemAd > 100) {
            setAlertMargem(
                'A soma dos valores de margens de apuração não podem passar de 100 !'
            );
            errorCheck = true;
            margemRef.current.scrollIntoView();
        } else {
            setAlertMargem('');
        }

        if (errorCheck) {
            return [setLoading(false)];
        }

        const data = new FormData();
        // var uploadedFile = uploadedFiles.filter(desc => !desc.url)

        var resultado = '';
        // let attachment = false ;
        // for(let i=0;i<uploadedFile.length;i++){
        //     if(uploadedFile[i].file){
        //         data.append(`file${i}`,uploadedFile[i].file);
        //         resultado = i;
        //         attachment = true
        //     }
        // }

        data.append('idProject', idProject.value);
        data.append('description', description);
        data.append('value', value);
        data.append('dateFirst', dateFirst);
        data.append('dateEnd', dateEnd);
        data.append('technical', JSON.stringify(technical));
        data.append('qtd', resultado);
        data.append('imposto', imposto);
        data.append('margemAdm', margemAdm);
        data.append('margemLqd', margemLqd);
        data.append('margemAd', margemAd);
        data.append('product', JSON.stringify(product));

        // console.log(data);
        // debugger

        if (props.match.params.action === 'edit') {
            data.append('_method', 'put');
        }

        if (props.match.params.action === 'edit') {
            const id = props.match.params.id;
            putExtraCharge(id, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    // setError([])
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        // setError('')
                        setAlert('');
                    }, 2500);
                });
        } else {
            postExtraCharge(data, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            })
                .then((resp) => {
                    setLoading(false);
                    setAlert('');
                    // setError([])
                    setSuccess(resp.data.success);
                })
                .then((resp) =>
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500)
                )
                .catch((e) => {
                    setLoading(false);
                    setSuccess('');
                    setAlert(e.response.data.errorSubmit);
                    setTimeout(() => {
                        // setError('')
                        setAlert('');
                    }, 3000);
                });
        }
    };

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage
                title={
                    props.match.params.action === 'edit'
                        ? 'Edição de Registro de Serviço Extra'
                        : props.match.params.action === 'view'
                        ? 'Visualização de Registro de Serviço Extra'
                        : 'Cadastro de Registro de Serviço Extra'
                }
            >
                {loading === true ? (
                    <Content>
                        <ReactLoading
                            type="spinningBubbles"
                            color="blue"
                            height={200}
                            width={100}
                        />
                    </Content>
                ) : (
                    ''
                )}
                {/* {alert ? (
                    <AlertNew type="danger">
                        {alert}
                    </AlertNew>
                ):('')} */}
                {/* {success ? (
                    <AlertNew type="success">
                        {success}
                    </AlertNew>
                ):('')} */}
                {success ? (
                    <div>
                        <Alert
                            type="success"
                            onCloseAlert={() => setSuccess('')}
                        >
                            {success}
                        </Alert>
                    </div>
                ) : (
                    ''
                )}
                {alert ? (
                    <div>
                        <Alert type="danger" onCloseAlert={() => setAlert('')}>
                            {alert}
                        </Alert>
                    </div>
                ) : (
                    ''
                )}
                <div
                    style={{
                        width: '100%',
                        display: loading === true ? 'none' : 'block',
                        opacity: success || alert ? 0.1 : 1,
                    }}
                >
                    <Form onSubmit={handleForm}>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <label>Projeto *</label>
                                <Select
                                    required={true}
                                    value={idProject}
                                    ref={selectProjectRef}
                                    label="Projeto"
                                    options={optionsProject}
                                    onChange={(e) => setIdProject(e)}
                                    isDisabled={
                                        props.match.params.action === 'view'
                                            ? true
                                            : false
                                    }
                                />
                                {!idProject && (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: '100%',
                                            height: 0,
                                            position: 'absolute',
                                        }}
                                        defaultValue={idProject}
                                        onFocus={() =>
                                            selectProjectRef.current.focus()
                                        }
                                        required={true}
                                    />
                                )}
                            </div>
                        </div>
                        {idProject && (
                            <>
                                {props.match.params.action && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Input
                                                icon="fas fa-key"
                                                label="Código"
                                                name="codigo"
                                                required={true}
                                                type="text"
                                                placeholder=""
                                                disabled={true}
                                                value={codigo}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input
                                            icon="fas fa-calendar"
                                            label="Data Início *"
                                            min={dateFirstProject}
                                            max={dateEndProject}
                                            name="validity"
                                            required={true}
                                            type="date"
                                            placeholder=""
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                setDateFirst(e.target.value)
                                            }
                                            value={dateFirst}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input
                                            icon="fas fa-calendar"
                                            label="Data Fim *"
                                            name="validity"
                                            min={dateFirst}
                                            max={dateEndProject}
                                            type="date"
                                            required={true}
                                            placeholder=""
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                setDateEnd(e.target.value)
                                            }
                                            value={dateEnd}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Input
                                            icon="fas fa-calendar"
                                            label="Data Início do Projeto"
                                            name="validity"
                                            type="date"
                                            placeholder=""
                                            disabled={true}
                                            value={dateFirstProject}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input
                                            icon="fas fa-calendar"
                                            label="Data Final do Projeto"
                                            name="validity"
                                            type="date"
                                            placeholder=""
                                            disabled={true}
                                            value={dateEndProject}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <TextArea
                                            required={true}
                                            rows="6"
                                            label="Descrição do Serviço *"
                                            value={description}
                                            maxLength="255"
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <NumberFormat
                                            icon="fas fa-money-check-alt"
                                            label="Valor a ser cobrado do cliente *"
                                            name="value"
                                            required={true}
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setValue(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={value}
                                            disabled={true}
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 9999999
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <br></br>
                                <div className="card-header">
                                    <h3 ref={produtoRef} className="card-title">
                                        Produtos *
                                    </h3>
                                </div>
                                {alertProduto ? (
                                    <div>
                                        <Alert type="danger">
                                            {alertProduto}
                                        </Alert>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="row mt-3">
                                    <div className="col-md-5">
                                        <label>Produto *</label>
                                        <Select
                                            value={idProduct}
                                            label="typeCharge"
                                            options={optionsProduct}
                                            onChange={(e) => setIdProduct(e)}
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            ref={selectProductRef}
                                        />
                                        {product.length <= 0 && (
                                            <input
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{
                                                    opacity: 0,
                                                    width: '100%',
                                                    height: 0,
                                                    position: 'absolute',
                                                }}
                                                defaultValue={idProduct}
                                                onFocus={() =>
                                                    selectProductRef.current.focus()
                                                }
                                                required={true}
                                            />
                                        )}
                                    </div>
                                    <div className="col-md-5">
                                        <NumberFormat
                                            icon="fas fa-money-check-alt"
                                            label="Valor *"
                                            name="valueProduct"
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setValueProduct(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={valueProduct}
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 999999999
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <div
                                            className="input-group mb-3"
                                            style={{ paddingTop: '30px' }}
                                        >
                                            <button
                                                onClick={handleProduct}
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                type="button"
                                                className="btn btn-primary"
                                            >
                                                <span className="fas fa-plus"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <TableProducts>
                                        <Products
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            product={product}
                                            formatReal={formatReal}
                                            handleDeleteProduct={
                                                handleDeleteProduct
                                            }
                                        />
                                    </TableProducts>
                                </div>
                                <hr></hr>
                                <br></br>
                                <div className="card-header">
                                    <h3 ref={margemRef} className="card-title">
                                        Margens de Apuração *
                                    </h3>
                                </div>
                                {alertMargem ? (
                                    <Alert type="danger">{alertMargem}</Alert>
                                ) : (
                                    ''
                                )}
                                <div className="row mt-3">
                                    <div className={`col-md-3`}>
                                        <NumberFormat
                                            icon="fas fa-money-bill-wave"
                                            label="Impostos *"
                                            name="validity"
                                            required={true}
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setImposto(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={imposto}
                                            disabled={true}
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 100
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={`col-md-3`}>
                                        <NumberFormat
                                            icon="fas fa-money-bill-wave"
                                            label="Margem Administrativa *"
                                            name="validity"
                                            required={true}
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setMargemAdm(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={margemAdm}
                                            disabled={true}
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 100
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={`col-md-3`}>
                                        <NumberFormat
                                            icon="fas fa-money-bill-wave"
                                            label="Margem Líquida *"
                                            name="validity"
                                            required={true}
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setMargemLqd(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={margemLqd}
                                            disabled={true}
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 100
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={`col-md-3`}>
                                        <NumberFormat
                                            icon="fas fa-money-bill-wave"
                                            label="Margem Adicional *"
                                            name="validity"
                                            required={true}
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setMargemAd(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={margemAd}
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 100
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <hr></hr>
                                <br></br>
                                <div className="card-header">
                                    <h3
                                        ref={technicalRef}
                                        className="card-title"
                                    >
                                        Técnicos e/ou Estagiários Envolvidos *
                                    </h3>
                                </div>
                                {alertTecnico ? (
                                    <div>
                                        <Alert type="danger">
                                            {alertTecnico}
                                        </Alert>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {errorTechnical && (
                                    <div>
                                        <Alert type="danger">
                                            {errorTechnical}
                                        </Alert>
                                    </div>
                                )}

                                <br></br>
                                <div className="text-right">
                                    <div
                                        style={{
                                            width: '100%',
                                            marginBottom: '20px',
                                            position: 'absolute',
                                            bottom: '0%',
                                            left: '-2%',
                                        }}
                                    >
                                        <button
                                            style={{ marginRight: '10px' }}
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => history.goBack()}
                                        >
                                            Voltar
                                        </button>

                                        {props.match.params.action ===
                                        'view' ? (
                                            <Link
                                                style={{
                                                    cursor:
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? 'not-allowed'
                                                            : '',
                                                    color:
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? '#fff'
                                                            : '',
                                                    backgroundColor:
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? '#007bff'
                                                            : '',
                                                    borderColor:
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? '#007bff'
                                                            : '',
                                                    opacity:
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? 0.65
                                                            : '',
                                                    boxShadow:
                                                        created_by !==
                                                            id_user ||
                                                        id_status !== 1
                                                            ? 'none'
                                                            : '',
                                                }}
                                                onClick={
                                                    created_by !== id_user ||
                                                    id_status !== 1
                                                        ? (e) =>
                                                              e.preventDefault()
                                                        : ''
                                                }
                                                to={`/extra_charge/${props.match.params.id}/edit`}
                                                className="btn btn-primary"
                                            >
                                                Editar
                                            </Link>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </Form>
                    {idProject && (
                        <>
                            <Form onSubmit={handleTechnical}>
                                <div className="row mt-4">
                                    <div className="col-md-4">
                                        <label>Técnico *</label>
                                        <Select
                                            value={idTechnical}
                                            onChange={(e) => setIdTechnical(e)}
                                            options={optionsTechnical}
                                            isDisabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            label="Single select"
                                            ref={selectTechnicalRef}
                                        />
                                        {idTechnical.length <= 0 && (
                                            <input
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{
                                                    opacity: 0,
                                                    width: '100%',
                                                    height: 0,
                                                    position: 'absolute',
                                                }}
                                                defaultValue={idTechnical}
                                                onFocus={() =>
                                                    selectTechnicalRef.current.focus()
                                                }
                                                required={true}
                                            />
                                        )}
                                    </div>
                                    {/* <div className="col-md-4">
                                    <label>Remuneração</label>
                                    <Select 
                                        label="Single select"
                                        value={idRemuneration}
                                        options={optionsRemuneration}
                                        onChange={(e) => setIdRemuneration(e)}
                                        ref={selectRemunerationRef}
                                        isDisabled={props.match.params.action === 'view' ? true : false}   
                                    /> 
                                    {idRemuneration.length <= 0 && 
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: "100%",
                                                height: 0,
                                                position: "absolute"
                                            }}
                                            defaultValue={idRemuneration}
                                            onFocus={() => selectRemunerationRef.current.focus()}
                                            required={true}
                                        />
                                    } 
                                    
                                </div> */}
                                    <div className="col-md-4">
                                        <NumberFormat
                                            icon="fas fa-dollar-sign"
                                            label="Dedicação (Dias) *"
                                            name="dedicacao"
                                            decimalScale={0}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setDedication(
                                                    parseInt(e.target.value)
                                                )
                                            }
                                            value={dedication}
                                            required={true}
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 1000
                                                );
                                            }}
                                        />
                                        {/* {props.errorValueTechnical} */}
                                    </div>
                                    <div className="col-md-3">
                                        <NumberFormat
                                            icon="fas fa-dollar-sign"
                                            label="Valor Diária *"
                                            name="valor"
                                            allowNegative={false}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            placeholder=""
                                            customInput={Input}
                                            onChange={(e) =>
                                                setValueTechnical(
                                                    parseFloat(
                                                        e.target.value
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')
                                                    )
                                                )
                                            }
                                            value={valueTechnical}
                                            required={true}
                                            disabled={
                                                props.match.params.action ===
                                                'view'
                                                    ? true
                                                    : false
                                            }
                                            isAllowed={(values) => {
                                                const {
                                                    floatValue,
                                                    formattedValue,
                                                } = values;
                                                return (
                                                    formattedValue === '' ||
                                                    floatValue <= 9999999
                                                );
                                            }}
                                        />
                                        {/* {props.errorValueTechnical} */}
                                    </div>
                                    <div className="col-md-1">
                                        <div
                                            className="input-group mb-3"
                                            style={{ paddingTop: '30px' }}
                                        >
                                            <button
                                                disabled={
                                                    props.match.params
                                                        .action === 'view'
                                                        ? true
                                                        : false
                                                }
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                <span className="fas fa-plus"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div style={{ marginBottom: '100px' }}>
                                <TableTechnicalExtraCharge>
                                    <Technical
                                        isDisabled={
                                            props.match.params.action === 'view'
                                                ? true
                                                : false
                                        }
                                        technical={technical}
                                        formatReal={formatReal}
                                        handleDeleteTechnical={
                                            handleDeleteTechnical
                                        }
                                    />
                                </TableTechnicalExtraCharge>
                            </div>
                        </>
                    )}
                </div>
            </StylePage>
            <Footer />
        </div>
    );
}

export default ExtraCharge;
